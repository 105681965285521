import styled from "styled-components";
import { COLORS } from "../../utils/Theme";
import ProductsListsGrid from "../ProductsListsGrid";




const MainDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: stretch;
   margin: 0px 5px 10px 5px;
`;





const HeaderDiv = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 11px;
`;



const SubCategoryTitle = styled.h2`
   color: ${COLORS.colorInstagramBlack};
   font-size: 16px;
   font-weight: bold;
`;










function RecommendedProductsComponent({siteDetails,sectionTitle,siteProducts,navigate,setRefreshShoppingCartItemsCountRandomLong,affiliateLinkId}) {

    return <MainDiv>
        <HeaderDiv>
            <SubCategoryTitle>
                {sectionTitle}
                <span style={{fontWeight:"bold",fontSize:14,color:COLORS.colorGray}}>{" (" + siteProducts.length + " Products)"}</span>
            </SubCategoryTitle>
        </HeaderDiv>
        <ProductsListsGrid siteDetails={siteDetails} siteProducts={siteProducts} navigate={navigate} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong} affiliateLinkId={affiliateLinkId}/>
    </MainDiv>

}




export default RecommendedProductsComponent;

