import styled from "styled-components";
import { COLORS } from "../utils/Theme";
import ordersIcon from "../icons/orders.svg"
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../utils/Api";
import { copyTextToClipBoard, printPrice } from "../utils/CommonMethods";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { hasLoggedIn } from "../utils/AppConstants";
import LoginOrSignUpComponent from "./shared/LoginOrSignUpComponent";


const HeaderMainContainerDummy = styled.div`
    visibility: hidden;
    height: 50px;
`;



const HeaderMainContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;
    background-color: ${COLORS.colorWhite};
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0px 10px 0px 16px;
`;





const SiteName = styled.h1`
    font-size: 16px;
    font-weight: bold;
    margin-right: auto;
`;



const OrdersIcon = styled.img`
    width: 30px;
    height: 25px;
`;





const ShopppingCartIcnDiv = styled.div`
   width: 45px;
   height: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   cursor: pointer;
   opacity: ${props => props.shoppingCartIsOpen ? ".3" : "1"};
   color: ${COLORS.colorInstagramBlack};
`;




const ShopppingCartAmountDiv = styled.p`
   position: absolute;
   right: 0px;
   top: 2px;
   color: white;
   font-size: 10px;
   font-weight: bold;
   padding : 1px 5px;
   background: red;
   border: solid 1px white;
   border-radius: 10px;
`;





function ShoppingCartBtn({shoppingCartIsOpen,shoppingCartItemsCount = 0,setShoppingCartIsOpen}) {

    return <ShopppingCartIcnDiv shoppingCartIsOpen={shoppingCartIsOpen} onClick={() => setShoppingCartIsOpen(isOpen => !isOpen)}>
               <ShoppingCartOutlinedIcon style={{fontSize:"24"}}/>
               {(shoppingCartItemsCount > 0) && <ShopppingCartAmountDiv>{shoppingCartItemsCount}</ShopppingCartAmountDiv>}
       </ShopppingCartIcnDiv>

}



const InvalidAffiliateLinkId = styled.h2`
    margin: 0px 0px 5px 0px;
    padding: 10px;
    font-size: 15px;
    color: ${COLORS.colorWhite};
    font-weight: normal;
    background-color: ${COLORS.colorInstagramRedOrange};
    border-radius: 3px;
    text-align: center;
    font-weight: bold;
`;





const JoinAffiliationSalesBannerDiv = styled.div`
    padding: 16px 13px;
    margin: 0px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(138,58,185,1) 0%, rgba(188,42,141,1) 100%);
    cursor: pointer;
`;



const JoinAffiliationSalesBannerTxt = styled.h3`
    font-size: 16px;
    color: white;
    font-weight: normal;
`;


const JoinAffiliationSalesBannerLearnMore = styled.div`
    margin: 3px 0px 0px 0px;
    padding: 0px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
`;




function JoinAffiliationSalesBanner({siteDetails}) {

    const [showModal,setShowModal] = useState();


    return <Fragment>
      <JoinAffiliationSalesBannerDiv onClick={() => setShowModal(true)}>
        <JoinAffiliationSalesBannerTxt>
            Join our online affiliate sales campaign and easily earn
            <span style={{fontSize:17,fontWeight:'bold'}}>{" " + printPrice(siteDetails.affiliate_sales_pay_amount) + " "}</span>
            per every product sold 
        </JoinAffiliationSalesBannerTxt>
        <JoinAffiliationSalesBannerLearnMore>
            Learn More
            <ChevronRightIcon/>
        </JoinAffiliationSalesBannerLearnMore>
      </JoinAffiliationSalesBannerDiv>
    {showModal && <JoinAffiliateSalesModal siteDetails={siteDetails} onCloseModal={() => setShowModal(false)}/>}
    </Fragment>

}






const JoinAffiliateSalesModalDiv = styled.div`
     position: fixed;
     width: 100%;
     height: 100%;
     top: 0px;
     left:0px;
     z-index: 100;
     background: rgba(0,0,0,.7);
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: stretch;
`;



const JoinAffiliateSalesModalContent = styled.div`
    padding: 16px;
    margin: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(138,58,185,1) 0%, rgba(188,42,141,1) 100%);
    position: relative;
    color: ${COLORS.colorWhite};
`;




const JoinAffiliationSalesTitleTxt = styled.h3`
    font-size: 20px;
    color: white;
    font-weight: bold;
    align-self: center;
    margin: 0px 0px 10px 0px;
`;



const JoinAffiliationSalesSubTitleTxt = styled.h3`
    margin: 3px 0px 0px 0px;
    font-size: 15px;
    color: white;
    font-weight: normal;
`;







const JoinNowButton = styled.button`
   width: 100%;
   height: 46px;
   border-radius: 23px;
   background-color: ${COLORS.colorWhite};
   border-style: none;
   color: ${COLORS.colorInstagramPurpleViolet};
   font-size: 18px;
   font-weight: bold;
   margin-top: 25px;
   margin-bottom: 13px;
   opacity: ${props => props.isSubmitting ? .3 : 1};
`;




const JoinAffiliationSalesBulletItemDiv = styled.div`
    margin: 7px 0px 0px 0px;
    padding: 0px;
    display: flex;
`;



const JoinAffiliationSalesBulletItem = styled.div`
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: white;
    margin: 9px 6px 0px 0px;
    flex-shrink: 0;
`;





function JoinAffiliateSalesModal({siteDetails,onCloseModal}) {


    const apiClient = useApi();
    const [isSubmitting,setIsSubmitting] = useState();
    const [showLoginModal,setShowLoginModal] = useState();

    function onJoinClicked() {

        if (isSubmitting) {
            return;
        } else if (!hasLoggedIn()) {
            setShowLoginModal(true);
            return;
        }

        apiClient.getFull(
            "/shop_site/web/get_or_create_my_affiliate_link_id",
            {site_id:siteDetails.site_id},
            resp => {
                // success
                window.location.reload();
            },errpr => {
                console.log(errpr);
                window.location.reload();
            });
        

        setIsSubmitting(true);

    }

    return <Fragment>
      <JoinAffiliateSalesModalDiv>
        <JoinAffiliateSalesModalContent>
            <CloseIcon 
               style={{display:(isSubmitting ? "none" : undefined),width:40,height:36,position:'absolute',top:13,right:10}}
               onClick={onCloseModal}/>
            <JoinAffiliationSalesTitleTxt>Sales Campaign</JoinAffiliationSalesTitleTxt>
            <JoinAffiliationSalesSubTitleTxt>
                Join our sales campaign and easily earn a commision for every product sold.
                <br/>
                <br/>
                All you have to do is generate product affiliate links from our product detail page and share it with people on WhatsApp, Facebook or TikTok.
                <br/>
                <br/>
                After that, for every paid order generated from your affiliate links, you will get paid.
            </JoinAffiliationSalesSubTitleTxt>
            <JoinAffiliationSalesBulletItemDiv>
                <JoinAffiliationSalesBulletItem/>
                <JoinAffiliationSalesSubTitleTxt>
                    Get paid 
                    <span style={{fontWeight:'bold'}}>{" " + printPrice(siteDetails.affiliate_sales_pay_amount) + " "}</span>
                    per prouct
                </JoinAffiliationSalesSubTitleTxt>
            </JoinAffiliationSalesBulletItemDiv>
            <JoinAffiliationSalesBulletItemDiv>
                <JoinAffiliationSalesBulletItem/>
                <JoinAffiliationSalesSubTitleTxt>
                    <span style={{fontWeight:'bolder'}}>ZERO COST FOR YOU</span>
                    {" - "}
                    You don't have to purchase any of our products you only share affiliate links  
                </JoinAffiliationSalesSubTitleTxt>
            </JoinAffiliationSalesBulletItemDiv>
            <JoinAffiliationSalesBulletItemDiv>
                <JoinAffiliationSalesBulletItem/>
                <JoinAffiliationSalesSubTitleTxt>
                    Withdraw your earnings every
                    <span style={{fontWeight:'bolder'}}> 3 days</span>    
                </JoinAffiliationSalesSubTitleTxt>
            </JoinAffiliationSalesBulletItemDiv>
            <JoinNowButton isSubmitting={isSubmitting} onClick={onJoinClicked}>Join Now</JoinNowButton>   
        </JoinAffiliateSalesModalContent>
      </JoinAffiliateSalesModalDiv>
      {showLoginModal 
        && <LoginOrSignUpComponent 
              siteDetails={siteDetails} 
              onLoginSucced={() => {
                onJoinClicked();
                setShowLoginModal(false);
              }} 
              onCancelled={() => setShowLoginModal(false)}/>}
    </Fragment>


}







const MyAffiliationSalesMiniDashBoardDiv = styled.div`
    padding: 16px 13px 8px 13px;
    margin: 0px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(138,58,185,1) 0%, rgba(76,104,215,1) 100%);
`;






const MyAffiliationSalesMiniDashBoardTitleTxt = styled.h3`
    font-size: 17px;
    color: white;
    font-weight: normal;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
`;



const MyAffiliationSalesMiniDashBoardSubTitleTxt = styled.h3`
    font-size: 15px;
    color: white;
    font-weight: normal;
`;



const MyAffiliationSalesMiniDashBoardCopyLink = styled.span`
    font-size: 11px;
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 5px;
    color: ${COLORS.colorInstagramBlue};
    background-color: ${COLORS.colorWhite};
    cursor: pointer;
`;





const AffiliateLinkDiv = styled.div`
    display: flex;
    flex-direction: column;
    color: ${COLORS.colorLightGray};
    font-size: 13px;
    word-break:break-all;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 5px;
`;


const CopyUrlTxt = styled.h3`
    background-color: white;
    border-radius: 5px;
    color: ${COLORS.colorInstagramBlue};
    font-size: 12px;
    font-weight: bold;
    padding: 6px 15px;
    align-self: flex-end;
    margin-top: 10px;
    margin-bottom: 0px;
    cursor: pointer;
`;






function MyAffiliationSalesMiniDashBoard({myAffiliateLinkInfo,navigate,pathname}) {


    const [generatedAffiliateLink,setGeneratedAffiliatLink] = useState();
    const [copiedTextBtnClicked,setCopiedTxtBtnClicked] = useState();

    useEffect(() => {

        if (pathname && generatedAffiliateLink) {
            setGeneratedAffiliatLink(window.location.href + "&affiliateLinkId=" + myAffiliateLinkInfo.affiliate_link_id);
        }

    },[pathname])


    return <MyAffiliationSalesMiniDashBoardDiv>
        <MyAffiliationSalesMiniDashBoardTitleTxt>
            Sales Dashboard
            {!generatedAffiliateLink && <MyAffiliationSalesMiniDashBoardCopyLink onClick={() => setGeneratedAffiliatLink(window.location.href + "&affiliateLinkId=" + myAffiliateLinkInfo.affiliate_link_id)}>Get Link</MyAffiliationSalesMiniDashBoardCopyLink>}
        </MyAffiliationSalesMiniDashBoardTitleTxt>
        {generatedAffiliateLink && <Fragment>
               <div style={{margin:"25px 0px 15px 0px",flexShrink:0,height:.5,backgroundColor:COLORS.colorWhite}}/>       
               <AffiliateLinkDiv>
                 {generatedAffiliateLink}
                 <CopyUrlTxt onClick={() => {copyTextToClipBoard(generatedAffiliateLink);setCopiedTxtBtnClicked(true)}}>{copiedTextBtnClicked ? "Copied!" : "Copy Link"}</CopyUrlTxt>
               </AffiliateLinkDiv>
            </Fragment>} 
        <div style={{margin:"15px 0px",flexShrink:0,height:.5,backgroundColor:COLORS.colorWhite}}/>
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <MyAffiliationSalesMiniDashBoardSubTitleTxt>
                Link Clicks : 
                <span style={{fontWeight:'bold'}}>{" " + myAffiliateLinkInfo.total_link_clicks}</span>
            </MyAffiliationSalesMiniDashBoardSubTitleTxt>
            <MyAffiliationSalesMiniDashBoardSubTitleTxt>
                Balance : 
                <span style={{fontWeight:'bold'}}>{" " + printPrice(myAffiliateLinkInfo.current_earnings_balance)}</span>
            </MyAffiliationSalesMiniDashBoardSubTitleTxt>
        </div>
        <MyAffiliationSalesMiniDashBoardSubTitleTxt onClick={() => navigate("/myAffiliateSalesDashBoard?siteId=" + myAffiliateLinkInfo.site_id)} style={{cursor:'pointer',padding:"10px 0px",alignSelf:'flex-end',fontSize:13,display:'flex',alignItems:'center'}}>
             View Details
             <ChevronRightIcon style={{fontSize:13}}/>
        </MyAffiliationSalesMiniDashBoardSubTitleTxt>
    </MyAffiliationSalesMiniDashBoardDiv>

}











function Header({isLoading,siteDetails,shoppingCartIsOpen,shoppingCartItemsCount,setShoppingCartIsOpen,affiliateLinkId,myAffiliateLinkInfo,pathname}) {


    const navigate = useNavigate();
    const apiClient = useApi();
    const [affiliationLinkIdIsInvalid,setAffiliationLinkIdIsInvalid] = useState();

    useEffect(() => {

        // check if the affiliate link is valid
        if (affiliateLinkId) {

            apiClient.postJson(
                "/shop_site/web/check_if_affiliate_link_id_is_valid",
                {site_id:siteDetails.site_id,affiliateLinkId},
                resp => setAffiliationLinkIdIsInvalid(!resp.exists),
                error => console.log(error));

        }

    },[affiliateLinkId]);


    return <Fragment>
        <HeaderMainContainerDummy/>
        <HeaderMainContainer>
          <SiteName onClick={() => navigate("/?siteId=" + siteDetails.site_id + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : ""))}>{siteDetails.site_header_title}</SiteName>
          <OrdersIcon onClick={() => navigate("/myOrders?siteId=" + siteDetails.site_id + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : ""))} src={ordersIcon}/>
          <ShoppingCartBtn shoppingCartIsOpen={shoppingCartIsOpen} shoppingCartItemsCount={shoppingCartItemsCount} setShoppingCartIsOpen={setShoppingCartIsOpen}/>
       </HeaderMainContainer>
       {affiliationLinkIdIsInvalid && <InvalidAffiliateLinkId>This sales affiliation link is invalid</InvalidAffiliateLinkId>}
       {!isLoading && siteDetails.affiliate_sales_pay_amount && !affiliateLinkId && pathname !== "/myAffiliateSalesDashBoard" && (myAffiliateLinkInfo ? <MyAffiliationSalesMiniDashBoard myAffiliateLinkInfo={myAffiliateLinkInfo} navigate={navigate} pathname={pathname}/> : <JoinAffiliationSalesBanner siteDetails={siteDetails}/>)}
    </Fragment>


}


export default Header;