let userJson;

export function getUserJson() {
    return userJson;
}

export function hasLoggedIn() {
    return (userJson !== null) && (userJson !== undefined);
}

export function setUserJson(siteId,newUserJson) {
    userJson = newUserJson;
    const storageKey = "user_json__" + siteId;
    if (newUserJson) {
       localStorage.setItem(storageKey,JSON.stringify(newUserJson));
    } else {
        localStorage.removeItem(storageKey);
    }
}


export function loadUserJson(siteId) {
    const storageKey = "user_json__" + siteId;
    userJson = localStorage.getItem(storageKey) && JSON.parse(localStorage.getItem(storageKey));
}


export function setUserPhone(phone_number) {
    userJson.phone_number = phone_number;
    setUserJson(userJson);
}


function useAppConstants() {
    return {getUserJson,hasLoggedIn};
}


export default useAppConstants;