import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import useLocalStateManager from "../../utils/LocalStateManager";
import useApi from "../../utils/Api";
import { LoadingComponent } from "../../App";
import styled from "styled-components";
import ProductImagesComponent from "./ProductImagesComponent";
import ProductCenterInfo from "./ProductCenterInfo";
import useImageLoader from "../../utils/ImageLoader";
import ProductsListsGrid from "../ProductsListsGrid";
import { COLORS } from "../../utils/Theme";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ProductBuyNowComponent from "./ProductBuyNowComponent";
import { generateSeoMetaTags, printPriceOnly } from "../../utils/CommonMethods";
import useAppConstants from "../../utils/AppConstants";
import { ApiErrorDiv } from "../../utils/ReusesableComponents";



const MainDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: stretch;
   width: 100%;
`;





const OtherProducts = styled.h2`
   margin: 0px 0px 15px 0px;
   color: ${COLORS.colorInstagramBlack};
   font-size: 15px;
   align-self: center;
   font-weight: normal;
`;



const Title = styled.h1`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding:5px 16px 10px 16px;
   font-size: 15px;
   color: ${COLORS.colorInstagramBlack};
   align-self:flex-start;
   font-weight: bold;
`;


const WebsiteTitleLink = styled(Link)`
   color:${COLORS.colorInstagramBlack};
   cursor: pointer;
   text-decoration: none;
   font-weight: normal;
`;



const RightArrowIcon = styled(ArrowForwardIosSharpIcon)`
   margin: 0px 6px;
   color:${COLORS.colorInstagramBlack};
`;




const ErrorOrLoadingContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center ;
   align-items: center;
   min-height: calc(100vh - 150px);
`;





function ProductDetails({siteDetails,setIsLoading,setRefreshShoppingCartItemsCountRandomLong,setOrderCheckoutParams,affiliateLinkId,myAffiliateLinkInfo}) {

    const navigate = useNavigate();
    const apiClient = useApi();

    const appConstants = useAppConstants();

    const searchParams = useSearchParams()[0];
    const siteProductId  = searchParams.get("siteProductId");
    const subCategoryId = searchParams.get("subCategoryId");

    const localStateManager = useLocalStateManager("productDetails",siteProductId);

    const [pageData,setPageData] = useState(localStateManager.getState("product_data") || null);

    const [refreshData,setRefreshData] = useState(pageData == null);

    const [storedProductId,setStoredProductId] = useState(siteProductId);

    const imageClient = useImageLoader();

    const [currentFocusedMediaItems,setCurrentFocusedMediaItems] = useState();

    const [errorTxt,setErrorTxt] = useState();

    const onPageLoaded = useCallback((pgDta) => {
        
            generateSeoMetaTags(siteDetails.site_header_title + " - " + pgDta.product_data.product_label_code,imageClient.getUrlForImg(100,pgDta.product_data.cover_img.media_data),"Price: " + "Tsh " + printPriceOnly(pgDta.price_info.price_retail_tzs));
            if (!window.prerenderReady) {
                window.prerenderReady = true;
            }

    },[]);
    
    useEffect(() => {
        if (storedProductId !== siteProductId) {
          if (localStateManager.hasState("product_data")) {
              setPageData(localStateManager.getState("product_data"));
              onPageLoaded(localStateManager.getState("product_data"));
          }else {
              setRefreshData(true);
              setIsLoading(true);
          }
          setStoredProductId(siteProductId);
       }
       window.scrollTo(0, 0);
      },[siteProductId]);


    useEffect(() => {

        if (refreshData) {

            apiClient.getFull(
                "/shop_site/web/get_product_details",
                {site_product_id:siteProductId,sub_category_id:subCategoryId,affiliateLinkId},
                resp => {
                    localStateManager.setState("product_data",resp);
                    setIsLoading(false);
                    setRefreshData(false);
                    setPageData(resp);
                    onPageLoaded(resp);
                },error => {
                    setIsLoading(false);
                    setRefreshData(false);
                    setErrorTxt(error);      
                });

            setIsLoading(true);
            setErrorTxt(null);      

        }

    },[refreshData]);



    let pageContent;




    if (refreshData) {
        pageContent = <LoadingComponent/>
    } else if (pageData) {
        pageContent = <Fragment>
            <Title>
                {pageData.sub_category_product_info ? <WebsiteTitleLink to={"/viewSubCategoryProducts?siteId=" + siteDetails.site_id + "&subCategoryId=" + pageData.sub_category_product_info.sub_category_id + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : "")}>{pageData.sub_category_product_info.sub_category_title}</WebsiteTitleLink> : <WebsiteTitleLink to={"/?siteId=" + siteDetails.site_id + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : "")}>Home</WebsiteTitleLink>}
                <RightArrowIcon style={{width:12,height:12}}/>
                {pageData.product_data.product_label_code}
            </Title>
            <ProductImagesComponent productData={pageData} imageClient={imageClient} currentFocusedMediaItems={currentFocusedMediaItems}/>
            <ProductCenterInfo productData={pageData} currentFocusedMediaItems={currentFocusedMediaItems} setCurrentFocusedMediaItems={setCurrentFocusedMediaItems}/>
            <ProductBuyNowComponent 
               siteDetails={siteDetails} 
               setRefreshData={setRefreshData} 
               hasLoggedIn={appConstants.hasLoggedIn()} 
               productData={pageData} 
               setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong} 
               setOrderCheckoutParams={setOrderCheckoutParams}
               myAffiliateLinkInfo={myAffiliateLinkInfo}
               apiClient={apiClient}/>
            {(pageData.other_site_products.length > 1) 
              && <Fragment>
                    <OtherProducts>Related Products</OtherProducts>
                    <ProductsListsGrid siteDetails={siteDetails} siteProducts={pageData.other_site_products} navigate={navigate} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong} affiliateLinkId={affiliateLinkId}/>
                </Fragment>}
        </Fragment>
    } else if (errorTxt) {
        pageContent = <ErrorOrLoadingContainer>
            <ApiErrorDiv error={errorTxt} refreshPage={() => setRefreshData(true)} refreshButtonText="Retry Again"/>
        </ErrorOrLoadingContainer>
    }


    return <MainDiv>
        {pageContent}
    </MainDiv>

    

}


export default ProductDetails;