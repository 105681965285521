import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useLocalStateManager from "../../utils/LocalStateManager";
import { LoadingComponent } from "../../App";
import useApi from "../../utils/Api";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { COLORS } from "../../utils/Theme";
import { generateSeoMetaTags } from "../../utils/CommonMethods";
import useAppConstants from "../../utils/AppConstants";
import { ApiErrorDiv, ApiNoResultsDiv } from "../../utils/ReusesableComponents";
import OrderListItemComponent from "./OrderListItemComponent";
import useImageLoader from "../../utils/ImageLoader";
import LoginOrSignUpComponent from "../shared/LoginOrSignUpComponent";



const MainDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: stretch;
   width: 100%;
`;




const Title = styled.h1`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding:5px 16px;
   font-size: 15px;
   color: ${COLORS.colorInstagramBlack};
   align-self:flex-start;
   font-weight: bold;
`;


const WebsiteTitleLink = styled(Link)`
   color:${COLORS.colorInstagramBlack};
   cursor: pointer;
   text-decoration: none;
   font-weight: normal;
`;



const RightArrowIcon = styled(ArrowForwardIosSharpIcon)`
   margin: 0px 6px;
   color:${COLORS.colorInstagramBlack};
`;





const ErrorOrLoadingContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center ;
   align-items: center;
   min-height: calc(100vh - 150px);
`;




const OrderStatusInfoDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: stretch;
   padding: 20px 15px;
   border-bottom: ${COLORS.colorLightGrayLighter} solid 5px;
`;


const OrderStatusTitle = styled.h3`
   color: ${COLORS.colorGray};
   font-size: 14px;
   font-weight: normal;
   margin-top: 0px;
   margin-bottom: 8px;
`;


const OrderStatusDivider = styled.div`
   flex-shrink: 0;
   height: 1px;
   opacity: .5;
   background-color: ${COLORS.colorLightGray};
   margin: 13px 0px 10px 0px;
`;


const OrderStatusInfo = styled.h3`
   color: ${COLORS.colorInstagramBlack};
   font-size: 14px;
   font-weight: normal;
`;






function MyOrders({siteDetails,affiliateLinkId,setIsLoading}) {

    const apiClient = useApi();
    
    const appState = useLocalStateManager("myOrders","");

    const [pageData,setPageData] = useState(appState.getState("page_data") || null);

    const [refreshData,setRefreshData] = useState(pageData == null);

    const appConstants = useAppConstants();

    const [errorTxt,setErrorTxt] = useState();

    const imageClient = useImageLoader();

    const navigate = useNavigate();

    const userHasLoggedIn = appConstants.hasLoggedIn();
    const [showLoginModal,setShowLoginModal] = useState(!userHasLoggedIn);


    useEffect(() => {

        if (userHasLoggedIn) {
            generateSeoMetaTags(siteDetails.site_header_title + " - My Orders",null,null);
        }

    },[]);


    useEffect(() => {

        if (refreshData && userHasLoggedIn) {
            
            apiClient.getFull(
                "/shop_site/web/my_orders",
                {site_id:siteDetails.site_id},
                resp => {
                    appState.setState("page_data",resp.data);
                    setPageData(resp.data);
                    setRefreshData(false);
                    setIsLoading(false);
                },error => {
                    setRefreshData(false);
                    setIsLoading(false);
                    setErrorTxt(error);
                });

            setIsLoading(true);
            setErrorTxt(null);
        }

    },[refreshData,userHasLoggedIn]);


    let pageContent;


    if (pageData) {

        let pageInnerContent;

        if (pageData.length === 0) {
            pageInnerContent = <ErrorOrLoadingContainer>
                <ApiNoResultsDiv error="We couldnt find any previous orders in your account" refreshPage={() => {setPageData(null);setRefreshData(true);}} refreshButtonText="Refresh Page"/>
            </ErrorOrLoadingContainer>
        } else {
            pageInnerContent = <Fragment>
                {pageData.map(order => <OrderListItemComponent key={order.ordered_time} orderItem={order} imageClient={imageClient}/>)}
                <OrderStatusInfoDiv>
                    <OrderStatusTitle>Order Statuses Description</OrderStatusTitle>
                    <OrderStatusInfo>
                        <span style={{color:COLORS.colorGray,fontWeight:'bold'}}>Unconfirmed</span>
                        {" - This is right after you submitted your order. It means we haven't checked to confirm the details with you. We usually confirm orders within 30 minutes"}
                    </OrderStatusInfo>
                    <OrderStatusDivider/>
                    <OrderStatusInfo>
                        <span style={{color:COLORS.colorInstagramBlack,fontWeight:'bold'}}>Confirmed</span>
                        {" - This means we have already confirmed your order details such as payment method and delivery fees. And this means your order is on route to being delivered"}
                    </OrderStatusInfo>
                    <OrderStatusDivider/>
                    <OrderStatusInfo>
                        <span style={{color:COLORS.colorInstagramBlue,fontWeight:'bold'}}>Delivered</span>
                        {" - This means your order has already been delivered to you"}
                    </OrderStatusInfo>
                    <OrderStatusDivider/>
                    <OrderStatusInfo>
                        <span style={{color:"red",fontWeight:'bold'}}>Cancelled</span>
                        {" - This means your order has been cancelled and its not valid anymore. Order cancellation is mostly due to lack of payment"}
                    </OrderStatusInfo>
                </OrderStatusInfoDiv>
            </Fragment>
        }

        pageContent = <Fragment>
            <Title>
                <WebsiteTitleLink to={"/?siteId=" + siteDetails.site_id + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : "")}>Home</WebsiteTitleLink>
                <RightArrowIcon style={{width:12,height:12}}/>
                My Orders
            </Title>
            {pageInnerContent}
        </Fragment>


    } else if (refreshData) {
        pageContent = <LoadingComponent/>
    } else if (errorTxt) {
        pageContent = <ErrorOrLoadingContainer>
            <ApiErrorDiv error={errorTxt} refreshPage={() => setRefreshData(true)} refreshButtonText="Retry Again"/>
        </ErrorOrLoadingContainer>
    }

    


    return <MainDiv>
        {pageContent}
        {showLoginModal && <LoginOrSignUpComponent siteDetails={siteDetails} onLoginSucced={() => setShowLoginModal(false)} onCancelled={() => navigate("/?siteId=" + siteDetails.site_id)}/>}
    </MainDiv>

}





export default MyOrders;