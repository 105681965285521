import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import { ApiErrorDiv } from "../../utils/ReusesableComponents";
import { printPrice } from "../../utils/CommonMethods";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import useApi from "../../utils/Api";
import useImageLoader from "../../utils/ImageLoader";
import StoreIcon from '@mui/icons-material/Store';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LoadingSpinner } from "../../App";
import { COLORS } from "../../utils/Theme";
import { useNavigate } from "react-router-dom";
import useAppConstants from "../../utils/AppConstants";
import { tanzanianPhoneNumberRegex } from "../shared/LoginOrSignUpComponent";
import { clearAllData } from "../../utils/LocalStateManager";



const ModalDiv = styled.div`
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0px;
   left:0px;
   background-color: rgba(0,0,0,.8);
   z-index: 100;
   overflow-y: auto;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: stretch;
`;


const MainDiv = styled.div`
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
`;



const ErrorOrLoadingContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center ;
   align-items: center;
   flex-grow: 1;
`;





const HeaderDiv = styled.div`
    height: 70px;
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-bottom: ${COLORS.colorLightGrayLighter} solid 5px;
    border-top-right-radius: 17px;
    border-top-left-radius: 17px;
`;


const HeaderCloseIcnSpan = styled.span`
    position: absolute;
    right: 10px;
    width: 36px;
    height: 36px;
    color: ${COLORS.colorInstagramBlack};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;


const HeaderText = styled.h2`
    margin: 0px;
    padding: 0px;
    color: ${COLORS.colorInstagramBlack};
    font-size: 20px;
`;




const ContentScrollDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    background-color: ${COLORS.colorLightGrayLighter};
    overflow-y: auto;
`;






const SiteAndProductItemsDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: white;
    border-bottom: ${COLORS.colorLightGrayLighter} solid 5px;
`;




const SiteInfoDiv = styled.div`
   display: flex;
   align-items: center;
   padding-top: 0px;
   padding-bottom: 5px;
`;

const SiteName = styled.h3`
   margin: 0px 3px 0px 5px;
   padding: 0px;
   font-size: 15px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;




const ProductItemDiv = styled.div`
   display: flex;
   align-items: center;
   margin: 10px 0px;
`;




const ProductItemCoverImgView = styled.div`
   width: 65px;
   height: 65px;
   flex-shrink: 0;
   margin-left: 0px;
   margin-right: 11px;
   position: relative;
`;








const ProductItemCoverImg = styled.img`
   width: 65px;
   height: 65px;
   flex-shrink: 0;
   object-fit: cover;
   border-radius: 4px;
`;


const ProductItemInfoDiv = styled.div`
   flex-grow: 1;
   flex-shrink: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
`;




const ProductTitle = styled.h3`
   margin: 0px;
   padding: 0px;
   font-size: 13px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;


const ProductItemTitle = styled.h3`
   color: ${COLORS.colorGray};
   align-self: flex-start;
   margin: 1px 0px;
   font-size: 12px;
   font-weight: normal;
`;


const ProductItemPriceToQuantityDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ProductItemPrice = styled.h3`
   margin: 0px;
   padding: 0px;
   font-size: 15px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;


const ProductItemQuantityAmount = styled.p`
    padding: 0px;
    margin: 0px;
    color: ${COLORS.colorInstagramBlack};
    font-size: 14px;
    font-weight: normal;
`;


function CreateProductItemDiv(productItem,imageClient) {

    return <Fragment key={"fragment_product_item_" + productItem.var_group_id}>
          <ProductItemDiv key={"shopping_bag_item_" + productItem.var_group_id}>
            <ProductItemCoverImgView>
              <ProductItemCoverImg src={imageClient.getUrlForImg(100,productItem.item_cover_img)}/>
            </ProductItemCoverImgView>
            <ProductItemInfoDiv>
                <ProductTitle>{productItem.product_label_code}</ProductTitle>
                <ProductItemTitle>{productItem.item_title}</ProductItemTitle>
                <ProductItemPriceToQuantityDiv>
                    <ProductItemPrice>{printPrice(productItem.item_price) + " "}</ProductItemPrice>
                    <ProductItemQuantityAmount>{"x" + productItem.quantity}</ProductItemQuantityAmount>
                </ProductItemPriceToQuantityDiv>
            </ProductItemInfoDiv>
         </ProductItemDiv>
    </Fragment>

}


function CreateProductItemsComponent(productItems,siteData,imageClient) {
    return <SiteAndProductItemsDiv>
        <SiteInfoDiv>
            <StoreIcon style={{color:COLORS.colorInstagramBlack,fontSize:"23"}}/>
            <SiteName>{siteData.site_header_title}</SiteName>
            <ChevronRightIcon style={{color:COLORS.colorInstagramBlack,fontSize:"17"}}/>
        </SiteInfoDiv>
        {productItems.map(productItem => CreateProductItemDiv(productItem,imageClient))}
    </SiteAndProductItemsDiv>
}



const PriceInfoDiv = styled.div`
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-bottom: ${COLORS.colorLightGrayLighter} solid 5px;
`;



const TotalPriceOrDeliveryFeeDiv = styled.div`
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


const TotalPriceOrDeliveryFeeStr = styled.h2`
    padding: 0px;
    margin: 0px;
    font-size: 15px;
    font-weight: normal;
    color: ${COLORS.colorInstagramBlack};
`;

const TotalPrice = styled.h2`
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
    color: ${COLORS.colorInstagramBlack};
`;



const Divider = styled.hr`
   margin: 0px;
   padding: 0px;
   align-self: stretch;
   border-top: 1px solid ${COLORS.colorLightGrayLighter};
`;









const ErrorTxt = styled.h3`
   flex-shrink: 0;
   padding: 9px 15px;
   color: white;
   font-size: 13px;
   font-weight: normal;
   text-align: center;
   background-color: red;
   border-bottom: ${COLORS.colorLightGrayLighter} solid 5px;
`;





const BottomDiv = styled.div`
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    margin-top: auto;
    margin-bottom: 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 65px;
    z-index: 1;
    border-top: ${COLORS.colorLightGrayLighter} solid 5px;
`;





const DeliveryPriceOrPaymentInfoDiv = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-bottom: ${COLORS.colorLightGrayLighter} solid 5px;
`;




const PaymentHeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;




const PaymentMethodSelectorDiv = styled.div`
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    height: 32px;
    width: 170px;
    border-radius: 6px;
    background-color: ${COLORS.colorLightGrayLighter};
    padding: 0px 3px;
`;



const PaymentMethodSelectorTxt = styled.h3`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: ${props => props.selected ? "bold" : "normal"};
    height: 28px;
    border-radius: 4px;
    flex: 1;
    background-color: ${props => props.selected ? COLORS.colorInstagramBlue : COLORS.colorLightGrayLighter};
    color: ${props => props.selected ? COLORS.colorWhite : COLORS.colorInstagramBlack};
`;





const DeliveryPriceInfoDivInfo = styled.h2`
    font-size: 14px;
    font-weight: normal;
    color: ${COLORS.colorInstagramBlack};
    white-space: pre-line;
`;





const TextInput = styled.input`
   border:none;
   outline:none;
   border-radius: 5px;
   padding: 13px 14px;
   font-size: 15px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
   background-color: rgb(232, 240, 254);

   &::placeholder {
       color: ${COLORS.colorGray};
       font-weight: bold;
   }

   &:focus {
       border: 2px solid ${COLORS.colorInstagramBlack};
       border-radius: 5px;
   }

   &::-ms-reveal {
       filter: invert(100%);
   }

`;







const TotalPayPrice = styled.h2`
    padding: 0px;
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    color: ${COLORS.colorInstagramBlack};
`;


const SubmitBtn = styled.button`
   font-size: 14px;
   font-weight: bold;
   color: white;
   padding: 9px 20px;
   background-color: ${COLORS.colorInstagramBlack};
   border-radius: 6px;
   border-style: none;
   outline: none;
   cursor: pointer;
`;




function OrderCheckOutComponent({orderParams,affiliateLinkId,myAffiliateLinkInfo,onCloseModal}) {

    const apiClient = useApi();
    const imageClient = useImageLoader();
    const navigate = useNavigate();

    const appConstants = useAppConstants();

    const [checkoutApiData,setCheckoutApiData] = useState();
    const [apiReqData,setApiReqData] = useState(); // {reqId,error}
    const [formError,setFormError] = useState();

    const paymentPayerPhoneInputRef = useRef();
    const paymentPayerNameInputRef = useRef();
    const paymentReceiptCodeInputRef = useRef();
    const payerContactPhoneNumberRef = useRef();
    const payerDeliveryAddressRef = useRef();

    const ON_DELIVERY = "ON_DELIVERY", PREPAY = "PREPAY";
    const [paymentMethod,setPaymentMethod] = useState(ON_DELIVERY);


    const {totalProductPrice,totalPayPrice} = useMemo(() => {
        if (checkoutApiData) {
            let totalProductPrice,totalPayPrice;

            for (let orderItem of checkoutApiData.ordered_product_items) {
                if (totalProductPrice) {
                    totalProductPrice.price = totalProductPrice.price + orderItem.total_price.price;
                }else {
                    totalProductPrice = {...orderItem.total_price};
                }
            }
            totalPayPrice = {...totalProductPrice};

            return {totalProductPrice,totalPayPrice};
        } else {
            return {};
        }
    },[checkoutApiData]);



    useEffect(() => {

        if (apiReqData && apiReqData.reqId) {
            apiClient.cancelRequest(apiReqData.reqId);
        }

        let reqId = apiClient.postJson(
            "/shop_site/web/init_checkout",
            orderParams,
            resp => {

                setCheckoutApiData(resp);
                setApiReqData(null);

            },error => setApiReqData({error,refreshPage:onCloseModal,refreshButtonText:"Ok! Got It"}));

        setApiReqData({reqId});    

    },[orderParams]);




    function submitOrder() {

        if (apiReqData && apiReqData.reqId) {
            return;
        }


        const postJson = {
            site_id:orderParams.site_id,
            order_items:orderParams.order_items,
        };


        if (paymentMethod === PREPAY) {

            const payerPhoneNumber = paymentPayerPhoneInputRef.current.value.trim();
            const payerName = paymentPayerNameInputRef.current.value.trim();
            const paymentReceiptCode = paymentReceiptCodeInputRef.current.value.trim();
    
            if (payerPhoneNumber.length === 0) {
                setFormError("Please enter payer's phone number");
                return;
            } else if (!tanzanianPhoneNumberRegex.test(payerPhoneNumber)) {
                setFormError("Please enter a valid phone number. +255784xxxxxx");
                return;
            }
    
            if (payerName.length === 0) {
                setFormError("Please enter payer's name");
                return;
            }
    
            if (paymentReceiptCode.length === 0){
                setFormError("Please enter the payment receipt (Muamala)");
                return;
            }

        } else if (paymentMethod === ON_DELIVERY) {

            const payerDeliveryAddress = payerDeliveryAddressRef.current.value.trim();
            const payerContactPhoneNumber = payerContactPhoneNumberRef.current.value.trim();

            if (payerDeliveryAddress.length === 0) {
                setFormError("Please enter the delivery location/address");
                return;
            }

            if (payerContactPhoneNumber.length === 0) {
                setFormError("Please enter payer's contact phone number");
                return;
            } else if (!tanzanianPhoneNumberRegex.test(payerContactPhoneNumber)) {
                setFormError("Please enter a valid phone number. +255784xxxxxx");
                return;
            }


            const site_order_params = {};
            site_order_params.payment_method = ON_DELIVERY;
            site_order_params.delivery_address = payerDeliveryAddress;
            site_order_params.contact_phone_number = payerContactPhoneNumber;
            site_order_params.payment_payer_name = appConstants.getUserJson().full_name;
            site_order_params.delivery_address_extra_notes = "";

            if (affiliateLinkId) {
                site_order_params.affiliateLinkId = affiliateLinkId;
            } else if (myAffiliateLinkInfo) {
                site_order_params.affiliateLinkId = myAffiliateLinkInfo.affiliate_link_id;
            }

            postJson.site_order_params = site_order_params;

        }

        let reqId = apiClient.postJson(
            "/shop_site/web/submit_order_request",
            postJson,
            res => {
                
                clearAllData();
                setApiReqData(null);
                onCloseModal();
                navigate("/myOrders?siteId=" + orderParams.site_id + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : ""));

            },error => {
                setFormError(error);
                setApiReqData(null);
            });

        setFormError(null);    
        setApiReqData({reqId});

    }



    let centerContent;

    if (apiReqData && apiReqData.reqId) {
        centerContent = <ErrorOrLoadingContainer>
            <LoadingSpinner/>
        </ErrorOrLoadingContainer>
    } else if (apiReqData && apiReqData.error) {
        centerContent = <ErrorOrLoadingContainer>
            <ApiErrorDiv {...apiReqData}/>
        </ErrorOrLoadingContainer>
    } else if (checkoutApiData) {
        centerContent = <>
            {CreateProductItemsComponent(checkoutApiData.ordered_product_items,checkoutApiData.site,imageClient)}
            <PriceInfoDiv>
                <TotalPriceOrDeliveryFeeDiv>
                    <TotalPriceOrDeliveryFeeStr>Total Price</TotalPriceOrDeliveryFeeStr>
                    <TotalPrice>{printPrice(totalProductPrice)}</TotalPrice>
                </TotalPriceOrDeliveryFeeDiv>
            </PriceInfoDiv>
            <DeliveryPriceOrPaymentInfoDiv>
                <TotalPriceOrDeliveryFeeStr>
                    Delivery Price Info
                </TotalPriceOrDeliveryFeeStr>
                <Divider style={{margin:"13px 0px"}}/>
                <DeliveryPriceInfoDivInfo>{checkoutApiData.site.delivery_policy_info}</DeliveryPriceInfoDivInfo>
                <TextInput
                   defaultValue={checkoutApiData.delivery_address_locations && checkoutApiData.delivery_address_locations[0]} 
                   style={{marginTop:15}}
                   placeholder="Enter the delivery location/address" 
                   type="text"
                   ref={payerDeliveryAddressRef}/>
            </DeliveryPriceOrPaymentInfoDiv>
            <DeliveryPriceOrPaymentInfoDiv style={{marginBottom:65}}>
                <PaymentHeaderDiv>
                    <TotalPriceOrDeliveryFeeStr>Payment Method</TotalPriceOrDeliveryFeeStr>
                    <PaymentMethodSelectorDiv>
                        <PaymentMethodSelectorTxt style={{opacity:.3}} selected={paymentMethod === PREPAY}>Prepay</PaymentMethodSelectorTxt>
                        <PaymentMethodSelectorTxt onClick={() => setPaymentMethod(ON_DELIVERY)} selected={paymentMethod === ON_DELIVERY}>On-Delivery</PaymentMethodSelectorTxt>
                    </PaymentMethodSelectorDiv>
                </PaymentHeaderDiv>
                {(paymentMethod === PREPAY) 
                   && <Fragment>
                 <Divider style={{margin:"13px 0px"}}/>
                 <DeliveryPriceInfoDivInfo style={{fontSize:14,fontWeight:'bold'}}>
                    {checkoutApiData.site.payment_info}
                 </DeliveryPriceInfoDivInfo>
                 <Divider style={{margin:"13px 0px"}}/>
                 <DeliveryPriceInfoDivInfo>{checkoutApiData.site.payment_instructions}</DeliveryPriceInfoDivInfo>
                 <Divider style={{margin:"13px 0px"}}/>
                 <TextInput 
                   defaultValue={appConstants.getUserJson().phone_number}
                   placeholder="Enter payer's phone (Namba Ya Mlipaji)" 
                   type="tel"
                   ref={paymentPayerPhoneInputRef}/>
                 <TextInput
                   defaultValue={appConstants.getUserJson().full_name}
                   style={{marginTop:13}} 
                   placeholder="Enter payer's name (Jina La Mlipaji)" 
                   type="text"
                   ref={paymentPayerNameInputRef}/>
                 <TextInput 
                   style={{marginTop:13}}
                   placeholder="Enter the payment receipt code (Muamala)" 
                   type="text"
                   ref={paymentReceiptCodeInputRef}/>
                </Fragment>}
                {(paymentMethod === ON_DELIVERY) 
                   && <Fragment>
                       <Divider style={{margin:"13px 0px"}}/>
                       <DeliveryPriceInfoDivInfo>
                          {checkoutApiData.site.payment_instructions}
                       </DeliveryPriceInfoDivInfo>
                       <TextInput
                          style={{marginTop:15}} 
                          defaultValue={appConstants.getUserJson().phone_number}
                          placeholder="Enter the contact phone" 
                          type="tel"
                          ref={payerContactPhoneNumberRef}/>
                    </Fragment>}
            </DeliveryPriceOrPaymentInfoDiv>
            <BottomDiv errorTxtIsVisible={formError}>
                <TotalPayPrice>
                    {printPrice(totalPayPrice)}
                    <span style={{fontSize:10,color:'red'}}>{" (Delivery fees not included)"}</span>
                </TotalPayPrice>
                <SubmitBtn onClick={submitOrder}>Submit</SubmitBtn>
            </BottomDiv>
        </>
    } else {
        centerContent = <></>
    }


    return <ModalDiv>
        <MainDiv>
            <HeaderDiv>
              <HeaderCloseIcnSpan>
                <CloseIcon style={{fontSize: "25"}} onClick={(e) => onCloseModal()}/>
              </HeaderCloseIcnSpan>
              <HeaderText>Order Checkout</HeaderText>
            </HeaderDiv>
            {formError && <ErrorTxt>{formError}</ErrorTxt>}
            <ContentScrollDiv>{centerContent}</ContentScrollDiv>
        </MainDiv>       
    </ModalDiv>


}


export default OrderCheckOutComponent;