import errorImg from "../icons/antenna.svg";
import notFound from "../icons/not-found.svg";
import { COLORS } from "./Theme";


export function ApiErrorDiv({error,refreshPage,refreshButtonText}) {

   let mainDivStyles = {
       color:COLORS.colorInstagramBlack, 
       width:'100%',
       height:'100%',
       display:'flex',
       justifyContent:'center',
       alignItems:'center',
       flexDirection:'column',
       textAlign:'center'
    } 

    let buttonStyles = {
        height:'46px',
        width:'80%',
        maxWidth:'300px',
        color:'white',
        fontSize:'15px',
        backgroundColor:COLORS.colorInstagramBlack,
        borderStyle:'none',
        borderRadius:'23px',
        textAlign:'center'
    }

   return <div style={mainDivStyles}>
            <img width="140px" src={errorImg}/>
            <p style={{color:COLORS.colorInstagramBlack,fontSize:'20px',margin:'40px',maxWidth:"600px"}}>{error}</p>
            {refreshPage && <button style={buttonStyles} onClick={e => refreshPage()}>{refreshButtonText}</button>}
          </div>
}


export function ApiNoResultsDiv({error,refreshPage,refreshButtonText}) {
    let mainDivStyles = {
        color:COLORS.colorInstagramBlack, 
        width:'100%',
        height:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        textAlign:'center'
     } 
 
     let buttonStyles = {
         height:'46px',
         width:'80%',
         maxWidth:'300px',
         color:'white',
         fontSize:'15px',
         fontWeight:'bold',
         backgroundColor:COLORS.colorInstagramBlack,
         borderStyle:'none',
         borderRadius:'23px',
         textAlign:'center'
     }
 
    return <div style={mainDivStyles}>
             <img width="140px" src={notFound}/>
             <p style={{color:COLORS.colorInstagramBlack,fontSize:'17px',marginLeft:'40px',marginRight:'40px',marginBottom:'30px',marginTop:'10px',maxWidth:"600px"}}>{error}</p>
             {refreshPage && <button style={buttonStyles} onClick={e => refreshPage()}>{refreshButtonText}</button>}
           </div>
}


ApiErrorDiv.defaultProps = {refreshButtonText:"Retry"};
ApiNoResultsDiv.defaultProps = {refreshButtonText:"Refresh"};


