import { useEffect, useRef } from "react";
import {baseURL} from "./Api";


function getImageBaseUrl() {
    return baseURL +  "/files/";
}

export function getImageUrlForFile(file) {
    return getImageBaseUrl() + file;
}

export function returnSmallestImageForImageSize(imageSize,imageObject){
    let returnPath = imageObject.org;
    try {
        let lastSavedWidth = 0;
        let lastSavedImgFile = undefined;
        for (let key in imageObject) {
            if (key === "org") {
                continue;
            }

            let keyInt = parseInt(key);

            if ((keyInt >= imageSize) && ((lastSavedWidth === 0) || (keyInt < lastSavedWidth)) ) {
                lastSavedWidth = keyInt;
                lastSavedImgFile = imageObject[key];
            }
        }

        if (lastSavedWidth !== 0) {
            returnPath = lastSavedImgFile;
        }
    }catch (e){
        console.log(e);
    }
    return returnPath;
}


// imageSize = image width
export function getImageUrl(imageSize,imageObject) {
   return getImageUrlForFile(returnSmallestImageForImageSize(imageSize,imageObject));
}


function useImageLoader() {

    let cachedImageUrlsRef = useRef(new Map()); 

    useEffect(() => {
        let cachedImageUrlsMap = cachedImageUrlsRef.current;
        return () => cachedImageUrlsMap.clear();
    },[]);

    let imageClient = {
        getUrlForImg(imageSize,imageObject) {
            let imgCacheKey = imageObject.org + "_" + imageSize;
            let imageUrl = cachedImageUrlsRef.current.get(imgCacheKey);
            if (!imageUrl) {
                imageUrl = getImageUrl(imageSize,imageObject);
                cachedImageUrlsRef.current.set(imgCacheKey,imageUrl);
            }
            return imageUrl;
        }
    }

    return imageClient;
}

export default useImageLoader;