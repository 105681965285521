import styled from "styled-components";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useImageLoader from "../../utils/ImageLoader";
import { Fragment, useCallback, useState } from "react";
import { printPriceOnly } from "../../utils/CommonMethods";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../utils/Theme";


const MainDiv = styled.div`
  margin: 10px 0px;
  border-radius: 17px;
`;


const ProductInfoDiv = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
`;

const ProductName = styled.h3`
   margin: 0px 4px 0px 10px;
   padding: 0px;
   font-size: 15px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
   cursor: pointer;
`;





const ShoppingBagItemDiv = styled.div`
   display: flex;
   align-items: center;
   margin: 17px 0px;
`;


const ShoppingBagItemCoverImg = styled.img`
   width: 80px;
   height: 80px;
   flex-shrink: 0;
   object-fit: cover;
   border-radius: 5px;
   margin-left: 12px;
   margin-right: 13px;
   cursor: pointer;
`;


const ShoppingBagItemInfoDiv = styled.div`
   flex-grow: 1;
   flex-shrink: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
`;


const ShoppingBagProductTitleToDeleteIcnDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


const ProductStockAmount = styled.h3`
   font-size: 13px;
   font-weight: normal;
   color: ${COLORS.colorInstagramBlack};
`;


const ShopProductGroupItemTitle = styled.h3`
   align-self: flex-start;
   margin: 5px 0px 3px 0px;
   padding: 2px 6px;
   font-size: 13px;
   font-weight: normal;
   background-color: ${COLORS.colorLightGrayLighter};
   border-radius: 3px;
   display: flex;
   align-items: center;
   cursor: pointer;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
`;


const ShoppingBagItemPriceToQuantityDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ShoppingBagItemPrice = styled.h3`
   margin: 0px;
   padding: 0px;
   font-size: 16px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;


const ShoppingBagQuantityDiv = styled.div`
   display: flex;
   align-items: center;
`;


const ShoppingBagQuantityAmount = styled.p`
    padding: 3px 8px;
    margin: 0px 3px;
    color: ${COLORS.colorInstagramBlack};
    font-size: 14px;
    font-weight: bold;
    background-color: ${COLORS.colorLightGrayLighter};
    border-radius: 2px;
`;



function CreateShoppingBagItemDiv(shoppingBagItem,productData,siteProductData,itemPrice,setDummyState,onDeleteItem,setUpdatingShoppingBagItem,navigateTo,apiClient,imageLoader,affiliateLinkId) {

    const varGroupData = shoppingBagItem.var_group_data;
    const canBuyItem = canBuyShoppingBagItem(shoppingBagItem,siteProductData);
    const stockAvailableQuantity = varGroupData ? varGroupData.var_group_stock_amount_info.available_for_sale_products : 0;


    function onItemSelectedOrUnSelected() {
        if (canBuyItem) {
          shoppingBagItem.selected = !shoppingBagItem.selected; 
          markVarGroupIdsAsSelected(apiClient,shoppingBagItem.site_id,[varGroupData.var_group_id],shoppingBagItem.selected);
          setDummyState();
        }
    }


    function onChangeQuantityAmount(add) {
        if (add) {
            if (shoppingBagItem.quantity >= stockAvailableQuantity) {
                return;
            }
            shoppingBagItem.quantity++;
        }else if (shoppingBagItem.quantity > 1) {
            shoppingBagItem.quantity--;
        }else {
            return;
        }
        increatementOrDecrementQuantity(apiClient,shoppingBagItem);
        setDummyState();
    }


    let coverImgUrl;
    if (varGroupData && varGroupData.display_media_items && varGroupData.display_media_items.length > 0) {
        coverImgUrl = imageLoader.getUrlForImg(100,varGroupData.display_media_items[0].media_data);
    }else {
        coverImgUrl = imageLoader.getUrlForImg(100,productData.cover_img.media_data);
    }

    let itemTitleElement;
    if (varGroupData) {
        if (siteProductData.is_visible) {
            if (varGroupData.is_none) {
                itemTitleElement = <span style={{width:"0px",height:"10px"}}/>
            }else {
               itemTitleElement = <ShopProductGroupItemTitle style={{color:COLORS.colorInstagramBlack}} onClick={e => setUpdatingShoppingBagItem(shoppingBagItem)}>
                    {varGroupData.var_group_title}
                    <KeyboardArrowDownIcon style={{color:COLORS.colorInstagramBlack,fontSize:"15"}}/>
                </ShopProductGroupItemTitle>
            }
        }else {
            itemTitleElement = <ShopProductGroupItemTitle style={{color:COLORS.colorInstagramRedOrange}} onClick={e => setUpdatingShoppingBagItem(shoppingBagItem)}>
                OUT OF STOCK
                <KeyboardArrowDownIcon style={{color:COLORS.colorInstagramRedOrange,fontSize:"15"}}/>
        </ShopProductGroupItemTitle>
        }
    } else {
        itemTitleElement = <ShopProductGroupItemTitle style={{color:COLORS.colorInstagramBlue}} onClick={e => setUpdatingShoppingBagItem(shoppingBagItem)}>
            SELECT VARIANT
            <KeyboardArrowDownIcon style={{color:COLORS.colorInstagramBlue,fontSize:"15"}}/>
        </ShopProductGroupItemTitle>
    }

    let checkBoxOpacity = canBuyItem ? 1 : .3;

    return <Fragment key={"fragment_shopping_bag_item_" + shoppingBagItem.var_group_id}>
          <ShoppingBagItemDiv key={"shopping_bag_item_" + shoppingBagItem.var_group_id}>
            {canBuyItem && shoppingBagItem.selected && <CheckBoxIcon onClick={e => onItemSelectedOrUnSelected()} style={{color:COLORS.colorInstagramBlack,fontSize:"25",cursor:"pointer"}}/>}
            {(!canBuyItem || !shoppingBagItem.selected) && <CheckBoxOutlineBlankIcon onClick={e => onItemSelectedOrUnSelected()} style={{color:COLORS.colorInstagramBlack,fontSize:"25",cursor:"pointer",opacity:checkBoxOpacity}}/>}
            <ShoppingBagItemCoverImg onClick={e => navigateTo("/productDetails?siteId=" + shoppingBagItem.site_id + "&siteProductId=" + shoppingBagItem.site_product_id + (affiliateLinkId ? ("&affiliateLinkId=" + affiliateLinkId) : ''))} src={coverImgUrl}/>
            <ShoppingBagItemInfoDiv>
                <ShoppingBagProductTitleToDeleteIcnDiv>
                    <ProductStockAmount>
                       {"Stock : "}
                       <span style={{fontWeight:'bold',color:COLORS.colorInstagramBlack}}>{stockAvailableQuantity}</span>
                    </ProductStockAmount>
                    <ClearIcon onClick={e => onDeleteItem(shoppingBagItem)} style={{color:COLORS.colorInstagramBlack,width:"23px",height:"23px",padding:"1px",cursor:"pointer"}}/>
                </ShoppingBagProductTitleToDeleteIcnDiv>
                {itemTitleElement}
                <ShoppingBagItemPriceToQuantityDiv>
                    <ShoppingBagItemPrice>{printPriceOnly(itemPrice) + " Tsh"}</ShoppingBagItemPrice>
                    <ShoppingBagQuantityDiv>
                        <RemoveIcon onClick={e => onChangeQuantityAmount(false)} style={{color:COLORS.colorInstagramBlack,width:"23px",height:"23px",padding:"1px",cursor:"pointer"}}/>
                        <ShoppingBagQuantityAmount>{shoppingBagItem.quantity}</ShoppingBagQuantityAmount>
                        <AddIcon onClick={e => onChangeQuantityAmount(true)} style={{color:COLORS.colorInstagramBlack,width:"23px",height:"23px",padding:"1px",cursor:"pointer"}}/>  
                    </ShoppingBagQuantityDiv>
                </ShoppingBagItemPriceToQuantityDiv>
            </ShoppingBagItemInfoDiv>
         </ShoppingBagItemDiv>
    </Fragment>

}


const BottomDiv = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;


const TotalPrice = styled.h2`
   margin: 0px;
   padding: 0px;
   font-size: 18px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;


const CheckoutBtn = styled.button`
   font-size: 12px;
   font-weight: bold;
   color: white;
   padding: 8px 14px;
   background-color: ${COLORS.colorInstagramBlack};
   border-radius: 6px;
   border-style: none;
   outline: none;
   opacity: ${props => props.isEnabled ? 1 : .3};
   cursor: ${props => props.isEnabled ? "pointer" : "not-allowed"};
   pointer-events: ${props => props.isEnabled ? "auto" : "none"};
`;







function ProductShoppingCartItemComponent({productShoppingCartItem,onRequestDeletion,apiClient,closeShoppingCart,setUpdatingShoppingBagItem,setOrderCheckoutParams,setRandomState,affiliateLinkId}) {

    const imageLoader = useImageLoader();
    const navigate = useNavigate();

    const setDummyStateValue = useState()[1];
    const productData = productShoppingCartItem.product_data;
    const shoppingBagItemsList = productShoppingCartItem.shopping_items;
    const siteProductData = productShoppingCartItem.site_product_data;


    function setDummyState() {
        setDummyStateValue(Date.now());
        setRandomState(Date.now());
    }


    function navigateTo(path) {
        closeShoppingCart();
        navigate(path);
    }


    const onCheckout = useCallback(() => {

        let order_items = shoppingBagItemsList
                    .filter(bagItem => canBuyShoppingBagItem(bagItem,siteProductData) && bagItem.selected)
                    .map(bagItem => {
                        return {var_group_id:bagItem.var_group_id,quantity:bagItem.quantity}
                    });

        closeShoppingCart();
        setTimeout(() => setOrderCheckoutParams({site_id:siteProductData.site_id,order_items}),300);

    },[]);


    function selectOrUnSelectAllCartItems(selected) {
        let varGroupIds = [];
        for (let bagItem of shoppingBagItemsList) {
            if (canBuyShoppingBagItem(bagItem,siteProductData)) {
                bagItem.selected = selected;
                varGroupIds.push(bagItem.var_group_id);
            }   
        }
        if (varGroupIds.length > 0) {
            markVarGroupIdsAsSelected(apiClient,siteProductData.site_id,varGroupIds,selected);
            setDummyState();
        }
    }


    function onDeleteItem(shoppingBagItem) {
        removeVarGroupFromShoppingCart(apiClient,shoppingBagItem.site_id,shoppingBagItem.var_group_id);
        let newShoppingBagItemsList = shoppingBagItemsList.filter(item => item.var_group_id !== shoppingBagItem.var_group_id);
        productShoppingCartItem.shopping_items = newShoppingBagItemsList;
        if (newShoppingBagItemsList.length === 0) {
            onRequestDeletion(shoppingBagItem.site_product_id);
        }else {
            setDummyState();
        }
    }


    let selectedShoppingBagItemsCount = 0;
    let totalQuantity = 0;

    for (let bagItem of shoppingBagItemsList) {
        if (canBuyShoppingBagItem(bagItem,siteProductData) && bagItem.selected) {
            totalQuantity+=bagItem.quantity;
            selectedShoppingBagItemsCount++;
        } 
    }


    const isRetailPrice = totalQuantity < 6;
    const itemPrice = isRetailPrice ? siteProductData.price_info.price_retail_tzs : siteProductData.price_info.price_wholesale_tzs;
    const totalPriceInfo = itemPrice * totalQuantity;



    return <MainDiv key={"product_cart_item_" + siteProductData.site_product_id}>
        <ProductInfoDiv>
            {selectedShoppingBagItemsCount === shoppingBagItemsList.length && <CheckBoxIcon onClick={e => selectOrUnSelectAllCartItems(false)} style={{color:COLORS.colorInstagramBlack,fontSize:"25",cursor:"pointer"}}/>}
            {selectedShoppingBagItemsCount !== shoppingBagItemsList.length && <CheckBoxOutlineBlankIcon onClick={e => selectOrUnSelectAllCartItems(true)} style={{color:COLORS.colorInstagramBlack,fontSize:"25",cursor:"pointer"}}/>}
            <ProductName onClick={e => navigateTo("/productDetails?siteId=" + siteProductData.site_id + "&siteProductId=" + siteProductData.site_product_id + (affiliateLinkId ? ("&affiliateLinkId=" + affiliateLinkId) : ""))}>{productData.product_label_code}</ProductName>
            <ChevronRightIcon style={{color:COLORS.colorInstagramBlack,fontSize:"17"}}/>
        </ProductInfoDiv>
        {shoppingBagItemsList.map(shoppingBagItem => CreateShoppingBagItemDiv(shoppingBagItem,productData,siteProductData,itemPrice,setDummyState,onDeleteItem,setUpdatingShoppingBagItem,navigateTo,apiClient,imageLoader,affiliateLinkId))}
        <BottomDiv>
            <TotalPrice>{totalPriceInfo ? printPriceOnly(totalPriceInfo) + " Tsh" : "0 Tsh"}</TotalPrice>
            <CheckoutBtn isEnabled={totalPriceInfo} onClick={e => onCheckout()}>Checkout</CheckoutBtn>
        </BottomDiv>
    </MainDiv>

}


function increatementOrDecrementQuantity(apiClient,shoppingBagItem) {
 
    let postJson = {
        site_id:shoppingBagItem.site_id,
        site_product_id:shoppingBagItem.site_product_id,
        source_product_id:shoppingBagItem.source_product_id,
        var_group_id:shoppingBagItem.var_group_id,
        quantity:shoppingBagItem.quantity
    }

    apiClient.postJson(
        "/shop_site/web/add_product_to_shopping_bag",
        postJson,
        resp => {},
        error => {});

}



export function canBuyShoppingBagItem(shoppingBagItem,siteProductData) {
    return siteProductData.is_visible 
        && shoppingBagItem.var_group_data 
        && (shoppingBagItem.var_group_data.var_group_stock_amount_info.available_for_sale_products >= shoppingBagItem.quantity);
}


function markVarGroupIdsAsSelected(apiClient,site_id,var_group_ids,selected) {
    apiClient.postJson("/shop_site/web/select_shopping_bag_items",
       {site_id,var_group_ids,selected},
       resp => {},
       error => {});
}

function removeVarGroupFromShoppingCart(apiClient,site_id,var_group_id) {
    apiClient.postJson("/shop_site/web/remove_product_from_shopping_bag",
       {site_id,var_group_id},
       resp => {},
       error => {});
}




export default ProductShoppingCartItemComponent;