import { Fragment } from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/Theme";
import { formatDateTime, printPriceOnly } from "../../utils/CommonMethods";




const MainDiv = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0px 16px;
`;




const SalesListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;



const TitleTxt = styled.h3`
    font-size: 15px;
    color: ${COLORS.colorInstagramBlack};
    flex: 1;
    font-weight: normal;
`; 


const Divider = styled.div`
   height: 1px;
   background-color: ${COLORS.colorLightGray};
   margin: 10px 0px;
`;



const ListItemTxt = styled.h3`
    font-size: 13px;
    color: ${COLORS.colorInstagramBlack};
    flex: 1;
    font-weight: normal;
    padding: 3px 0px;
`; 






function SalesItemsListComponent({salesOrderItems}) {


    let centerContent;


    if (salesOrderItems.length == 0) {
        centerContent = <div style={{height:200}}/>
    } else {

        centerContent = salesOrderItems.map(salesItem => {

            let itemPriceColor;
            if (salesItem.sales_item_status === "UNCONFIRMED") {
                itemPriceColor = COLORS.colorGray;
            } else if (salesItem.sales_item_status === "CONFIRMED") {
                itemPriceColor = "#0000b3";
            } else if (salesItem.sales_item_status === "CANCELLED") {
                itemPriceColor = "#B30000";
            }

            return <Fragment key={salesItem.sales_order_id}>
              <Divider/>
              <SalesListItem>
                <ListItemTxt style={{color:itemPriceColor}}>{salesItem.sales_order_customer_abbreviated_phone_no}</ListItemTxt>
                <ListItemTxt style={{fontSize:13,textAlign:'center',color:itemPriceColor}}>{formatDateTime(salesItem.sales_order_time)}</ListItemTxt>
                <ListItemTxt style={{textAlign:'end',fontWeight:'bold',color:itemPriceColor,fontSize:15}}>{printPriceOnly(salesItem.total_earnings_amount.price)}</ListItemTxt>
              </SalesListItem>
            </Fragment>

        });
    }


    return <MainDiv>
        <SalesListItem style={{marginBottom:10}}>
            <TitleTxt>Customer</TitleTxt>
            <TitleTxt style={{textAlign:'center'}}>Time</TitleTxt>
            <TitleTxt style={{textAlign:'end'}}>{"Earnings (Tsh)"}</TitleTxt>
        </SalesListItem>
        {centerContent}
    </MainDiv>


}




export default SalesItemsListComponent;