import { Fragment } from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/Theme";
import useImageLoader from "../../utils/ImageLoader";
import { printPrice, printPriceOnly } from "../../utils/CommonMethods";
import { useNavigate } from "react-router-dom";



const MainDiv = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0px 18px;
`;



const ProductListItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;




const Divider = styled.div`
   height: 3px;
   background-color: ${COLORS.colorLightGray};
   margin: 20px 0px;
`;

const SmallDivider = styled.div`
   height: 1px;
   background-color: ${COLORS.colorLightGray};
   margin: 12px 0px;
`;



const ProductInfoView = styled.div`
   display: flex;
   flex-direction: column;
   height: 60px;
   justify-content: center;
   padding: 0px 0px 0px 67px;
   position: relative;
   margin-top: 0px;
   margin-bottom: 5px;
`;


const ProductCoverImg = styled.img`
   height: 55px;
   width: 55px;
   border-radius: 5px;
   object-fit: cover;
   position: absolute;
   left: 0px;
`;



const ProductLabelCode = styled.h3`
   font-size: 16px;
   color: ${COLORS.colorInstagramBlack};
   font-weight: normal;
`;



const ProductPrice = styled.h3`
   font-size: 15px;
   color: ${COLORS.colorInstagramBlack};
   font-weight: bold;
`;



const SectionDiv = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
`;



const SectionTitle = styled.h2`
   font-size: 14px;
   font-weight: normal;
   color: ${COLORS.colorInstagramBlack};
`;



const SectionValue = styled.h2`
   font-size: 14px;
   font-weight: bold;
   color: ${props => props.color || COLORS.colorInstagramBlack};
`;







function SalesProductStats({productSales}) {

    const imageClient = useImageLoader();
    const navigate = useNavigate();

    let content;

    if (productSales.length == 0) {
        content = <div style={{height:200}}/>
    } else {
        content = productSales.map((productSale,idx) => <Fragment key={productSale.site_product_id}>
            {idx > 0 && <Divider/>}
            <ProductListItem>
                <ProductInfoView>
                    <ProductCoverImg onClick={() => navigate("/productDetails?siteId="+ productSale.site_id + "&siteProductId=" + productSale.site_product_id)} src={imageClient.getUrlForImg(45,productSale.site_product_data.product_data.cover_img.media_data)}/>
                    <ProductLabelCode>{productSale.site_product_data.product_data.product_label_code}</ProductLabelCode>
                    <ProductPrice>{printPriceOnly(productSale.site_product_data.price_info.price_retail_tzs)}</ProductPrice>
                </ProductInfoView>
                <SmallDivider/>
                <SectionDiv>
                    <SectionTitle>Page Clicks</SectionTitle>
                    <SectionValue>{productSale.product_page_clicks}</SectionValue>
                </SectionDiv>
                <SmallDivider/>
                <SectionDiv>
                    <SectionTitle>Confirmed Product Sales</SectionTitle>
                    <SectionValue color={COLORS.colorInstagramBlue}>{productSale.total_confirmed_product_sales + " Pcs"}</SectionValue>
                </SectionDiv>
                <SmallDivider/>
                <SectionDiv>
                    <SectionTitle>Pendiing Product Sales</SectionTitle>
                    <SectionValue color={COLORS.colorGray}>{productSale.total_pending_product_sales + " Pcs"}</SectionValue>
                </SectionDiv>
                <SmallDivider/>
                <SectionDiv>
                    <SectionTitle>Cancelled Product Sales</SectionTitle>
                    <SectionValue color={COLORS.colorInstagramRedOrange}>{productSale.total_cancelled_product_sales + " Pcs"}</SectionValue>
                </SectionDiv>
                <SmallDivider/>
                <SectionDiv>
                    <SectionTitle>Total Earnings</SectionTitle>
                    <SectionValue color={COLORS.colorInstagramBlue}>{printPrice(productSale.total_earnings)}</SectionValue>
                </SectionDiv>
            </ProductListItem>
        </Fragment>);
    }


    return <MainDiv>
        {content}
    </MainDiv>

}



export default SalesProductStats;