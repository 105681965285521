import styled from "styled-components";
import { formatDate, formatDateTime, printPriceOnly } from "../../utils/CommonMethods";
import { COLORS } from "../../utils/Theme";
import useImageLoader from "../../utils/ImageLoader";
import { Fragment, useState } from "react";
import ImageViewerComponent from "../shared/ImageViewerComponent";





const MainDiv = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0px 16px;
`;



const HeaderTxt = styled.h3`
    font-size: 14px;
    font-weight: normal;
`;




const SalesListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;



const TitleTxt = styled.h3`
    font-size: 15px;
    color: ${COLORS.colorInstagramBlack};
    flex: 1;
    font-weight: normal;
`; 


const Divider = styled.div`
   height: 1px;
   background-color: ${COLORS.colorLightGray};
   margin: 10px 0px;
`;



const ListItemTxt = styled.h3`
    font-size: 13px;
    color: ${COLORS.colorInstagramBlack};
    flex: 1;
    font-weight: normal;
    padding: 3px 0px;
`; 



const ReceiptImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-left: auto;
    object-fit: cover;
`; 





function WithdrawsComponent({myAffiliateLinkInfoData}) {

    const imageClient = useImageLoader();
    const [showImageViewer,setShowImageViewer] = useState();

    let centerContent;

    if (myAffiliateLinkInfoData.sales_earnings_withdrawals.length === 0) {
        centerContent = <div style={{height:200}}/>;
    } else {
        centerContent = myAffiliateLinkInfoData.sales_earnings_withdrawals
             .map(withdrawItem => <Fragment key={withdrawItem.withdrawal_time}>
                <Divider/>
                <SalesListItem>
                  <ListItemTxt style={{color:COLORS.colorInstagramBlue,fontWeight:'bold',fontSize:16}}>{printPriceOnly(withdrawItem.withdrawal_amount.price)}</ListItemTxt>
                  <ListItemTxt style={{fontSize:13,textAlign:'center'}}>{formatDateTime(withdrawItem.withdrawal_time)}</ListItemTxt>
                  <div style={{flex:1,display:'flex',justifyContent:'flex-end'}} onClick={() => setShowImageViewer(withdrawItem.withdrawal_payment_receipt)}>
                    <ReceiptImage src={imageClient.getUrlForImg(50,withdrawItem.withdrawal_payment_receipt)}/>
                  </div>
                </SalesListItem>
              </Fragment>);
    }


    return <MainDiv>
        <HeaderTxt>
            We issue withdraws every 3 to 4 days. We will issue your current balance withdraws on
            <span style={{fontWeight:'bold',color:COLORS.colorInstagramBlue}}>{" " + formatDate(myAffiliateLinkInfoData.next_eligible_withdrawal_time)}</span>
        </HeaderTxt>
        <SalesListItem style={{marginTop:20,marginBottom:10}}>
            <TitleTxt>{"Amount (Tsh)"}</TitleTxt>
            <TitleTxt style={{textAlign:'center'}}>Time</TitleTxt>
            <TitleTxt style={{textAlign:'end'}}>Receipt</TitleTxt>
        </SalesListItem>
        {centerContent}
        {showImageViewer && <ImageViewerComponent images={[showImageViewer]} onCloseModal={() => setShowImageViewer(false)}/>}
    </MainDiv>


}



export default WithdrawsComponent;