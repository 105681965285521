export const COLORS = {
    colorWhite:"#FFFFFF",
    colorDarkBlueLighter:"#02386e",
    colorInstagramBlack:"#262626",
    colorInstagramPurpleViolet:"#8a3ab9",
    colorInstagramRedViolet:"#bc2a8d",
    colorInstagramRedOrange:"#e95950",
    colorInstagramYellow:"#fccc63",
    colorInstagramOrange:"#fbad50",
    colorInstagramMaroon:"#cd486b",
    colorInstagramBlue:"#4c68d7",
    colorInstagramBlueOther:"#30618A",
    colorLightGray:"#DADDE1",
    colorLightGrayLighter:"#f5f6f7",
    colorGray:"#696969"
}