import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Room
} from "@material-ui/icons";
import styled from "styled-components";
import { COLORS } from "../utils/Theme";


const FooterContainer = styled.div`
   display: flex;
   justify-content: center;
`;

const FooterContent = styled.div`
  flex-shrink: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  color : ${COLORS.colorInstagramBlack};

  @media screen and (max-width:800px) {
       flex-direction: column;
  }

`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Logo = styled.h1`
  margin: 0px;
  padding: 0px;
  font-size: 25px;
`;

const Desc = styled.p`
  margin: 16px 0px;
  font-size: 15px;
`;

const SocialContainer = styled.div`
  display: flex;
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color : white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;
`;



const Title = styled.h3`
  margin-bottom: 20px;
  margin-top: 0px;
  padding: 0px;
`;



const Diver = styled.div`
   width: 1px;
   height: 85%;
   background-color: ${COLORS.colorLightGray};
   align-self: center;
   flex-shrink: 0;

   @media screen and (max-width:800px) {
     width: calc(100% - 40px);
     height: 1px;
     justify-self: center;
   }
`;


const Right = styled.div`
  flex: 1;
  padding: 16px;
`;

const ContactItem = styled.div`
  margin-top: 0px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-size: 15px;
`;


function Footer({siteDetails}) {  

  const {company_address,company_brief_description,company_email_address,company_facebook_page_url,company_instagram_page_url,company_name,company_whatsapp_phone_number} = siteDetails.site_footer;

  return (
    <FooterContainer>
       <FooterContent>
      <Left>
        <Logo>{company_name}</Logo>
        <Desc>{company_brief_description}</Desc>
        <SocialContainer>
          <SocialIcon onClick={e => window.open(company_facebook_page_url,"_blank")} color="3B5999">
            <Facebook />
          </SocialIcon>
          <SocialIcon onClick={e => window.open(company_instagram_page_url,"_blank")} color="E4405F">
            <Instagram />
          </SocialIcon>
        </SocialContainer>
      </Left>
      <Diver/>
      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <Room style={{marginTop:-2,marginRight:"10px",alignSelf:"flex-start"}}/>
          {company_address}
        </ContactItem>
        <ContactItem>
          <Phone style={{marginRight:"10px"}}/>
          {company_whatsapp_phone_number}
        </ContactItem>
        <ContactItem>
          <MailOutline style={{marginRight:"10px"}} />
          {company_email_address}
        </ContactItem>
      </Right>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
