import styled from "styled-components";
import { COLORS } from "../../utils/Theme";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ProductSubCategoryComponent from "./ProductSubCategoryComponent";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { generateSeoMetaTags } from "../../utils/CommonMethods";
import useImageLoader from "../../utils/ImageLoader";
import RecommendedProductsComponent from "./RecommendedProductsComponent";


const MainView = styled.div`
   align-items: stretch;
   display: flex;
   flex-direction: column;
`;



const CategoryOrSearchBtnParentView = styled.div`
    display: none;
    gap: 10px;
    margin: 10px;
`;


const CategoryOrSearchBtnsView = styled.div`
    background-color: ${COLORS.colorLightGray};
    height: 40px;
    border-radius: 6px;
    font-size: 13px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;












function SiteHome({siteDetails,myAffiliateLinkInfo,affiliateLinkId,setRefreshShoppingCartItemsCountRandomLong}) {

    const navigate = useNavigate();
    const imageClient = useImageLoader();

    useEffect(() => {
        
        const firstProductCoverImg = siteDetails.product_sub_categories[0]?.site_products[0]?.product_data?.cover_img?.media_data;
        generateSeoMetaTags(siteDetails.site_header_title,firstProductCoverImg ? imageClient.getUrlForImg(100,firstProductCoverImg) : "",siteDetails.site_footer.company_brief_description);
        if (!window.prerenderReady) {
            window.prerenderReady = true;
        }

    },[]);

    return <MainView>
        <CategoryOrSearchBtnParentView>
            <CategoryOrSearchBtnsView>
                Categories
                <ExpandMoreIcon/>
            </CategoryOrSearchBtnsView>
            <CategoryOrSearchBtnsView>
                <SearchIcon style={{fontSize:16}}/>
                Search
            </CategoryOrSearchBtnsView>
        </CategoryOrSearchBtnParentView>
        {myAffiliateLinkInfo && myAffiliateLinkInfo.recommended_products && (myAffiliateLinkInfo.recommended_products.length > 0) && <RecommendedProductsComponent siteDetails={siteDetails} sectionTitle="My Recommended Products" navigate={navigate} siteProducts={myAffiliateLinkInfo.recommended_products} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong} affiliateLinkId={affiliateLinkId}/>}
        {siteDetails.recommended_products && (siteDetails.recommended_products.length > 0) && <RecommendedProductsComponent siteDetails={siteDetails} sectionTitle="Recommended Products" navigate={navigate} siteProducts={siteDetails.recommended_products} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong} affiliateLinkId={affiliateLinkId}/>}
        {siteDetails.product_sub_categories.map(subCategory => <ProductSubCategoryComponent siteDetails={siteDetails} key={subCategory.sub_category_id} subCategory={subCategory} navigate={navigate} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong} affiliateLinkId={affiliateLinkId}/>)}
    </MainView>

}


export default SiteHome;