import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import ProductShoppingCartItemComponent, { canBuyShoppingBagItem } from "./ProductShoppingCartItemComponent";
import useAppConstants from "../../utils/AppConstants";
import useApi from "../../utils/Api";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ApiErrorDiv, ApiNoResultsDiv } from "../../utils/ReusesableComponents";
import AddToCartOrBuyNowModal from "./AddToCartOrBuyNowModal";
import LoginOrSignUpComponent from "../shared/LoginOrSignUpComponent";
import { COLORS } from "../../utils/Theme";
import { LoadingSpinner } from "../../App";
import SalesShoppingBagSummaryComponent from "./ShoppingBagSummaryComponent";


const Modal = styled.div`
   position: fixed;
   width: 100vw;
   height: 100vh;
   top: 0px;
   left: 0px;
   background: rgba(0,0,0,.6);
   z-index: 100;
   transform : translate(0,100%);
   transition : all .2s ease;
   transform: ${props => props.isOpen ? "translate(0,0)" : undefined};
`;



const MainDiv = styled.div`
   position: absolute;
   top: 60px;
   right: 0;
   width: 100%;
   height: calc(100% - 60px);
   max-width: 550px;
   background-color: ${COLORS.colorWhite};
   overflow-y: auto;
   display: flex;
   flex-direction: column;
   align-items: stretch;
   border-top-right-radius: 15px;
   border-top-left-radius: 15px;
`;



const Header = styled.div`
   height: 60px;
   flex-shrink: 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 2px 17px 0px 17px;
`;


const ShoppingCartTitle = styled.h1`
   font-size: 18px;
   color: ${COLORS.colorInstagramBlack};
   font-weight: bold;
   padding: 0px;
`;


const CartListItemsDiv = styled.div`
   flex-grow: 1;
   flex-shrink: 1;
   overflow-y: auto;
   padding: 0px 17px;
`;




const Divider = styled.hr`
   margin: 20px 0px;
   padding: 0px;
   align-self: stretch ;
   border-top: .3px solid ${COLORS.colorLightGray};
`;






const LoadingErrorOrEmptyDataContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center ;
  align-items: center;
`;


const CheckoutBtn = styled.button`
   flex-shrink: 0;
   height: 46px;
   font-size: 15px;
   font-weight: bold;
   color: white;
   padding: 8px 16px;
   margin: 8px 17px 15px 17px;
   background-color: ${COLORS.colorInstagramBlack};
   border-radius: 23px;
   border-style: none;
   outline: none;
   opacity: ${props => props.isEnabled ? 1 : .3};
`;






function ShoppingCartComponent({siteDetails,affiliateLinkId,shoppingCartIsOpen,setShoppingCartIsOpen,setShoppingCartItemsCount,refreshShoppingCartItemsCountRandomLong,setRefreshShoppingCartItemsCountRandomLong,setOrderCheckoutParams}) {


   const appConstants = useAppConstants();
   const apiClient = useApi();
   const [productShoppingCartList,setProductShoppingCartList] = useState(null);
   const [dataListApiReqId,setDataListApiReqId] = useState();
   const [dataListApiError,setDataListApiError] = useState();
   const [updatingShoppingBagItem,setUpdatingShoppingBagItem] = useState(); // ShoppingBagItem

   const [randomState,setRandomState] = useState();
   const hasLoggedInUser = appConstants.hasLoggedIn();


   // shpping cart items count
   useEffect(() => {

      if (hasLoggedInUser) {
         apiClient.getFull(
            "/shop_site/web/count_shopping_bag_items",
            {site_id:siteDetails.site_id},
             resp => setShoppingCartItemsCount(resp.amount),
             () => setShoppingCartItemsCount(0));
      }

   },[hasLoggedInUser,refreshShoppingCartItemsCountRandomLong,shoppingCartIsOpen]);
  


   // update shopping cart list
   useEffect(() => {
      if (shoppingCartIsOpen && hasLoggedInUser) {

         if (dataListApiReqId) {
            apiClient.cancelRequest(dataListApiReqId);
         }

         let apiReqId =  apiClient.getFull(
            "/shop_site/web/get_shopping_bag_items",
            {site_id:siteDetails.site_id},
            resp => {
               setProductShoppingCartList(resp.data);
               setDataListApiError(null);
               setDataListApiReqId(null);  
            },error => {
               setDataListApiError(error); 
               setDataListApiReqId(null); 
            });
         
         setDataListApiReqId(apiReqId);
         setDataListApiError(null);
          
      }
   },[refreshShoppingCartItemsCountRandomLong,shoppingCartIsOpen]);


   const shoppingCartItemsElement = useMemo(() => {
      if (productShoppingCartList && (productShoppingCartList.length > 0)) {

         function onRequestDeletion(removingItemSiteProductId) {
            let newProductShoppingCartList = productShoppingCartList.filter(cartItem => cartItem.site_product_data.site_product_id !== removingItemSiteProductId);
            setProductShoppingCartList(newProductShoppingCartList);
         }

         return <CartListItemsDiv>
                  {productShoppingCartList
                     .map((productShoppingCartItem,index) => {

                        return <Fragment key={"fragment_" + productShoppingCartItem.site_product_data.site_product_id}>
                              {(index === 0) && <Divider style={{marginTop:5}} key={"top_divider_" + index}/>}
                              <ProductShoppingCartItemComponent 
                                    key={"cart_item_" + productShoppingCartItem.site_product_data.site_product_id} 
                                    productShoppingCartItem={productShoppingCartItem} 
                                    onRequestDeletion={onRequestDeletion} 
                                    apiClient={apiClient}
                                    closeShoppingCart={() => setShoppingCartIsOpen(false)}
                                    setUpdatingShoppingBagItem={setUpdatingShoppingBagItem}
                                    setOrderCheckoutParams={setOrderCheckoutParams}
                                    setRandomState={setRandomState}
                                    affiliateLinkId={affiliateLinkId}/>
                              <Divider key={"divider_" + index}/>
                        </Fragment>

                     })}
               </CartListItemsDiv>
      
      }else {
         return <></>
      }
   },[productShoppingCartList]);



   const checkoutOrderItems = useMemo(() => {

      if (productShoppingCartList) {
         const orderItems = [];
         for (const cartItem of productShoppingCartList) {
            const siteProductData = cartItem.site_product_data;
            for (const bagItem of cartItem.shopping_items) {
               if (bagItem.selected && canBuyShoppingBagItem(bagItem,siteProductData)) {
                  orderItems.push({quantity:bagItem.quantity,var_group_id:bagItem.var_group_id});
               }
            }
         }
         return orderItems;
      }

      return [];

  },[randomState,productShoppingCartList]);




   let modalContent;

   if (shoppingCartIsOpen) {

      let centerContent;

      if (dataListApiReqId) {
         centerContent = <LoadingErrorOrEmptyDataContainer>
            <LoadingSpinner/>
         </LoadingErrorOrEmptyDataContainer>
      }else if (dataListApiError) {
         centerContent = <LoadingErrorOrEmptyDataContainer>
            <ApiErrorDiv error={dataListApiError} refreshPage={() => setRefreshShoppingCartItemsCountRandomLong(Date.now())}/>
         </LoadingErrorOrEmptyDataContainer>
      }else if (productShoppingCartList) {
         if (productShoppingCartList.length === 0) {
            centerContent = <LoadingErrorOrEmptyDataContainer>
                 <ApiNoResultsDiv error="We couldn't find any products in your shopping bag"
                     refreshButtonText="Continue Shopping"
                     refreshPage={() => setShoppingCartIsOpen(false)}/>
            </LoadingErrorOrEmptyDataContainer>
         }else {
            centerContent = <Fragment>
               {shoppingCartItemsElement}
               <SalesShoppingBagSummaryComponent randomState={randomState} shoppingCartList={productShoppingCartList}/>
               <CheckoutBtn isEnabled={checkoutOrderItems.length > 0} disabled={checkoutOrderItems.length === 0} onClick={() => {setOrderCheckoutParams({site_id:siteDetails.site_id,order_items:checkoutOrderItems});setShoppingCartIsOpen(false);}}>Checkout</CheckoutBtn>
            </Fragment>
         }
      }else {
         centerContent = <></>;
      }


      modalContent = <Fragment>
         <MainDiv onClick={e => e.stopPropagation()}>
         <Header>
            <ShoppingCartTitle>My Shopping Cart</ShoppingCartTitle>
            <CloseIcon style={{color:COLORS.colorInstagramBlack,fontSize:"30",cursor:"pointer"}} onClick={e => setShoppingCartIsOpen(false)}/>
         </Header>
         {centerContent}
         </MainDiv>
         {!hasLoggedInUser 
            && <LoginOrSignUpComponent
                  siteDetails={siteDetails} 
                  onLoginSucced={() => setRefreshShoppingCartItemsCountRandomLong(Date.now())} 
                  onCancelled={() => setShoppingCartIsOpen(false)}
                  affiliateLinkId={affiliateLinkId}/>}
         {updatingShoppingBagItem && <AddToCartOrBuyNowModal onAddToCart={() => setRefreshShoppingCartItemsCountRandomLong(Date.now())} openingMode="CART_MODE" siteProductId={updatingShoppingBagItem.site_product_id} sourceProductId={updatingShoppingBagItem.source_product_id} onCloseModal={e => setUpdatingShoppingBagItem(null)}/>}
      </Fragment>

   } else {
      modalContent = <></>
   }

   return <Modal isOpen={shoppingCartIsOpen} onClick={e => setShoppingCartIsOpen(false)}>
         {modalContent}
   </Modal> 

}



export default ShoppingCartComponent;

