import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import useApi, { refreshUserJsonAndUserData } from "./utils/Api";
import { ColorRing } from "react-loader-spinner";
import { COLORS } from "./utils/Theme";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SiteHome from "./components/siteHome/SiteHome";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ViewSubCategoryProducts from "./components/viewSubCategoryProducts/ViewSubCategoryProducts";
import ProductDetails from "./components/productDetails/ProductDetails";
import ShoppingCartComponent from "./components/shoppingCart/ShoppingCartComponent";
import OrderCheckOutComponent from "./components/checkout/OrderCheckOutComponent";
import MyOrders from "./components/myOrders/MyOrders";
import {hasLoggedIn} from "./utils/AppConstants";
import MyAffiliateSalesDashBoard from "./components/myAffiliateSalesDashBoard/MyAffiliateSalesDashBoard";


const AppContainer = styled.div`
   background-color:${COLORS.colorWhite};
`;



const LoadingDiv = styled.div`
  height: calc(100vh - 30px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center ;
  align-items: center;
`;



export function LoadingSpinner({}) {

   return  <ColorRing 
               visible={true}
               height="60"
               width="60"
               ariaLabel="blocks-loading"
               wrapperStyle={{}}
               wrapperClass="blocks-wrapper"
               colors={[COLORS.colorInstagramBlack]}/>

}



export function LoadingComponent() {

   return <LoadingDiv>
        <LoadingSpinner/>
     </LoadingDiv>

}





function App() {

  const apiClient = useApi();
  const [siteDetails,setSiteDetails] = useState();
  const [isLoading,setIsLoading] = useState(true);

  const navigate = useNavigate();

  const [shoppingCartIsOpen,setShoppingCartIsOpen] = useState(false);
  const [shoppingCartItemsCount,setShoppingCartItemsCount] = useState(0);
  const [refreshShoppingCartItemsCountRandomLong,setRefreshShoppingCartItemsCountRandomLong] = useState(0);
  const [orderCheckoutParams,setOrderCheckoutParams] = useState();
  
  const [myAffiliateLinkInfo,setMyAffiliateLinkInfo] = useState(null);

  const location = useLocation();

  const searchParams = useSearchParams()[0];
  const siteId = searchParams.get("siteId");
  const affiliateLinkId = searchParams.get("affiliateLinkId");

  useEffect(() => {

      if (siteId) {
         refreshUserJsonAndUserData(siteId);

         apiClient.getFull(
             "/shop_site/web/get_site_details",
             {site_id:siteId,affiliateLinkId},
             resp => {
                setIsLoading(false);
    
                if (resp.new_arrivals_sub_category) {
                   resp.product_sub_categories = [resp.new_arrivals_sub_category,...resp.product_sub_categories];
                }
    
                setSiteDetails(resp);
             },error => {
                console.log(error);
             });
      } else {
         navigate("/?siteId=" + "3bdfbb7ea12b2df5e26e1869d6c717f6");
      }

  },[siteId]);


  useEffect(() => {

     // try to get my affiliation link info
     if (hasLoggedIn() && !affiliateLinkId) {
      
        apiClient.getFull(
            "/shop_site/web/get_my_affiliate_link_id",
            {site_id:siteId},
            resp => {

               if (resp.affiliate_link_id) {
                  setMyAffiliateLinkInfo(resp);
               } else {
                  setMyAffiliateLinkInfo(null);
               }

            },error => console.log(error));

     } else {
        setMyAffiliateLinkInfo(null); 
     }

  },[hasLoggedIn(),siteId]);


  let centerContent;


  if (siteDetails) {
     centerContent = <Fragment>
        <Header 
           isLoading={isLoading}
           siteDetails={siteDetails}
           shoppingCartItemsCount={shoppingCartItemsCount}
           shoppingCartIsOpen={shoppingCartIsOpen} 
           setShoppingCartIsOpen={setShoppingCartIsOpen}
           affiliateLinkId={affiliateLinkId}
           myAffiliateLinkInfo={myAffiliateLinkInfo}
           pathname={location.pathname}/>
        <ShoppingCartComponent
           siteDetails={siteDetails}
           affiliateLinkId={affiliateLinkId} 
           shoppingCartIsOpen={shoppingCartIsOpen} 
           setShoppingCartIsOpen={setShoppingCartIsOpen} 
           setShoppingCartItemsCount={setShoppingCartItemsCount} 
           refreshShoppingCartItemsCountRandomLong={refreshShoppingCartItemsCountRandomLong}
           setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong}
           setOrderCheckoutParams={setOrderCheckoutParams}/>
        {orderCheckoutParams && <OrderCheckOutComponent orderParams={orderCheckoutParams} affiliateLinkId={affiliateLinkId} myAffiliateLinkInfo={myAffiliateLinkInfo} onCloseModal={() => setOrderCheckoutParams(null)} />}
        <Routes>
           <Route path="/" element={<SiteHome siteDetails={siteDetails} myAffiliateLinkInfo={myAffiliateLinkInfo} affiliateLinkId={affiliateLinkId} setIsLoading={setIsLoading} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong}/>}/>
           <Route path="/viewSubCategoryProducts" element={<ViewSubCategoryProducts siteDetails={siteDetails} affiliateLinkId={affiliateLinkId} setIsLoading={setIsLoading} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong}/>}/>
           <Route path="/productDetails" element={<ProductDetails siteDetails={siteDetails} affiliateLinkId={affiliateLinkId} setIsLoading={setIsLoading} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong} setOrderCheckoutParams={setOrderCheckoutParams} myAffiliateLinkInfo={myAffiliateLinkInfo}/>}/>
           <Route path="/myOrders" element={<MyOrders siteDetails={siteDetails} affiliateLinkId={affiliateLinkId} setIsLoading={setIsLoading}/>}/>
           <Route path="/myAffiliateSalesDashBoard" element={<MyAffiliateSalesDashBoard siteDetails={siteDetails} setMyAffiliateLinkInfo={setMyAffiliateLinkInfo} setIsLoading={setIsLoading}/>}/>
        </Routes>
        {isLoading || <Footer siteDetails={siteDetails}/>}
     </Fragment>
  } else {
     centerContent = <LoadingComponent/>
  }


  return  <AppContainer>{centerContent}</AppContainer>
}

export default App;
