import styled from "styled-components";
import { COLORS } from "../../utils/Theme";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Fragment, useCallback, useState } from "react";
import AddToCartOrBuyNowModal, { BUY_MODE, CART_MODE } from "../shoppingCart/AddToCartOrBuyNowModal";
import LoginOrSignUpComponent from "../shared/LoginOrSignUpComponent";


const ButtonsView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    padding: 5px 12px;
`;


const BuyNowOrAddToCartView = styled.div`
   display: flex;
   gap: 10px;
`;


const Button = styled.div`
    background: ${props => props.bgColor};
    height: 46px;
    border-radius: 6px;
    color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    gap: 6px;
    font-weight: 500;
    cursor: pointer;
`;



const Divider = styled.div`
   background-color: ${COLORS.colorLightGray};
   height: .3px;
   margin: 20px 0px;
`;




function ProductBuyNowComponent({siteDetails,setRefreshData,hasLoggedIn,productData,setRefreshShoppingCartItemsCountRandomLong,setOrderCheckoutParams,myAffiliateLinkInfo,apiClient}) {

  const [showAddToCartOrBuyOpeningMode,setShowAddToCartOrBuyOpeningMode] = useState();

  const [isAddingToAffiliateRecommendedList,setIsAddingToAffiliateRecommendedList] = useState();

  const onAddToCart = useCallback(() => {
    setRefreshShoppingCartItemsCountRandomLong(Date.now());
    setShowAddToCartOrBuyOpeningMode(null);
  },[productData]);


  function addOrRemoveToAffiliateRecommendedList() {

      if (isAddingToAffiliateRecommendedList) {
         return;
      }

      if (!myAffiliateLinkInfo.recommended_product_ids) {
         myAffiliateLinkInfo.recommended_product_ids = [];
      }

      const isAlreadyRecommended = myAffiliateLinkInfo.recommended_product_ids.includes(productData.site_product_id);
      
      apiClient.postJson(
        isAlreadyRecommended ? "/shop_site/web/remove_product_to_my_affiliate_sales_recommended_list" : "/shop_site/web/add_product_to_my_affiliate_sales_recommended_list",
        {site_id:siteDetails.site_id,site_product_id:productData.site_product_id},
        resp => {

          if (isAlreadyRecommended) {
              myAffiliateLinkInfo.recommended_product_ids = myAffiliateLinkInfo.recommended_product_ids.filter(id => id !== productData.site_product_id);
          } else {
              myAffiliateLinkInfo.recommended_product_ids = [productData.site_product_id,...myAffiliateLinkInfo.recommended_product_ids];
          }

          setIsAddingToAffiliateRecommendedList(false);

        },error => {
           console.log(error);
           setIsAddingToAffiliateRecommendedList(false);
        });

      setIsAddingToAffiliateRecommendedList(true);

  }



  return <Fragment>
      <ButtonsView>
        <BuyNowOrAddToCartView>
            <Button onClick={() => setShowAddToCartOrBuyOpeningMode(CART_MODE)} style={{flex:1}} color={COLORS.colorInstagramBlack} bgColor={COLORS.colorLightGray}>
              Add to Cart
              <ShoppingCartOutlinedIcon style={{fontSize:14}}/>
            </Button>
            <Button onClick={() => setShowAddToCartOrBuyOpeningMode(BUY_MODE)} style={{flex:1}} color={COLORS.colorWhite} bgColor={COLORS.colorInstagramBlack}>
              Order Now
            </Button>
        </BuyNowOrAddToCartView>
        <Button onClick={() => contactSeller(siteDetails)} color={COLORS.colorWhite} bgColor={COLORS.colorInstagramBlue}>
           Contact Us
           <WhatsAppIcon style={{fontSize:15}}/>
        </Button>
        {myAffiliateLinkInfo && <Button onClick={addOrRemoveToAffiliateRecommendedList} style={{opacity:isAddingToAffiliateRecommendedList ? .3 : 1}}  color={COLORS.colorWhite} bgColor="linear-gradient(90deg, rgba(138,58,185,1) 0%, rgba(76,104,215,1) 100%)">
              {(myAffiliateLinkInfo.recommended_product_ids && myAffiliateLinkInfo.recommended_product_ids.includes(productData.site_product_id)) ? "Remove From Recommended" : "Add to Recommended"}
         </Button>}
      </ButtonsView>
      <Divider/>
      {showAddToCartOrBuyOpeningMode && hasLoggedIn && <AddToCartOrBuyNowModal onAddToCart={onAddToCart} openingMode={showAddToCartOrBuyOpeningMode}  siteProductId={productData.site_product_id} sourceProductId={productData.source_product_id} onCloseModal={e => setShowAddToCartOrBuyOpeningMode(null)} setOrderCheckoutParams={setOrderCheckoutParams}/>}
      {showAddToCartOrBuyOpeningMode && !hasLoggedIn && <LoginOrSignUpComponent siteDetails={siteDetails} onLoginSucced={() => setRefreshData(true)} onCancelled={() => setShowAddToCartOrBuyOpeningMode(null)}/>}
    </Fragment>


}




function contactSeller(siteDetails) {

    const productPageUrl = window.location.href;
    const messageStr = "Hello " + siteDetails.site_header_title + " am currently interested in this product " + productPageUrl;
    const url = "https://api.whatsapp.com/send?phone="+ siteDetails.site_footer.company_whatsapp_phone_number + "&text=" + encodeURIComponent(messageStr);
    window.open(url, "_blank");

}





export default ProductBuyNowComponent;

