import styled from "styled-components";
import { generateSeoMetaTags, printPrice } from "../../utils/CommonMethods";
import useApi from "../../utils/Api";
import { Fragment, useEffect, useState } from "react";
import useLocalStateManager from "../../utils/LocalStateManager";
import { hasLoggedIn } from "../../utils/AppConstants";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../utils/Theme";
import { ApiErrorDiv } from "../../utils/ReusesableComponents";
import { LoadingComponent } from "../../App";
import SalesItemsListComponent from "./SalesItemsListComponent";
import SalesProductStats from "./SalesProductStats";
import WithdrawsComponent from "./WithdrawsComponent";


const MainDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: stretch;
   width: 100%;
`;




const ErrorOrLoadingContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center ;
   align-items: center;
   min-height: calc(100vh - 150px);
`;








const HeaderViewDiv = styled.div`
    padding: 16px 13px;
    margin: 0px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(138,58,185,1) 0%, rgba(76,104,215,1) 100%);    
`;






const HeaderViewTitleTxt = styled.h3`
    font-size: 18px;
    color: white;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
`;



const HeaderViewSidesDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px 13px 0px;
`;


const HeaderViewSubTitleTxt = styled.h3`
    font-size: 15px;
    color: white;
    font-weight: normal;
`;




const TabMainDiv = styled.div`
   display: flex;
   height: 50px;
   margin: 15px 0px 5px 0px;
   background-color: rgba(255,255,255,0.101);
   border-radius: 25px;
   align-items: center;
   position: relative;
`;


const TabMainSelectedView = styled.div`
    position: absolute;
    top: 0px;
    width: 33.3%;
    height: 100%;
    background-color: ${COLORS.colorWhite};
    border-radius: 25px;
    transition : left .2s ease;
`;



const TabTxt = styled.h2`
   z-index:1;
   flex: 1;
   font-size: 16px;
   text-align: center;
   font-weight: ${props => props.isSelected ? "bold" : "normal"};
   color: ${props => props.isSelected ? COLORS.colorInstagramBlue : COLORS.colorWhite};   
   cursor: pointer;
`;









function HeaderView(myAffiliateLinkInfoData,selectedTab,setCurrentSelectedTab) {


    return <HeaderViewDiv>
        <HeaderViewTitleTxt>Sales Dashboard</HeaderViewTitleTxt>
        <div style={{margin:"15px 0px 10px 0px",flexShrink:0,height:.5,backgroundColor:COLORS.colorWhite}}/>
        <HeaderViewSubTitleTxt>{"Product Sales (Pcs)"}</HeaderViewSubTitleTxt>
        <HeaderViewSidesDiv style={{justifyContent:'space-between'}}>
            <HeaderViewSubTitleTxt>
                Confirmed : 
                <span style={{fontWeight:'bold'}}>{" " + myAffiliateLinkInfoData.total_confirmed_product_sales_amount}</span>
            </HeaderViewSubTitleTxt>
            <HeaderViewSubTitleTxt>
                Pending : 
                <span style={{fontWeight:'bold'}}>{" " + myAffiliateLinkInfoData.total_pending_product_sales_amount}</span>
            </HeaderViewSubTitleTxt>
            <HeaderViewSubTitleTxt>
                Cancelled : 
                <span style={{fontWeight:'bold'}}>{" " + myAffiliateLinkInfoData.total_cancelled_product_sales_amount}</span>
            </HeaderViewSubTitleTxt>
        </HeaderViewSidesDiv>
        <HeaderViewSubTitleTxt>{"Earnings"}</HeaderViewSubTitleTxt>
        <HeaderViewSidesDiv>
            <HeaderViewSubTitleTxt>
                Total : 
                <span style={{fontWeight:'bold'}}>{" " + printPrice(myAffiliateLinkInfoData.total_earnings)}</span>
            </HeaderViewSubTitleTxt>
            <HeaderViewSubTitleTxt>
                Withdrawn : 
                <span style={{fontWeight:'bold'}}>{" " + printPrice(myAffiliateLinkInfoData.total_withdrawn_earnings)}</span>
            </HeaderViewSubTitleTxt>
        </HeaderViewSidesDiv>
        <div style={{margin:"10px 0px",flexShrink:0,height:.5,backgroundColor:COLORS.colorWhite}}/>
        <HeaderViewSidesDiv>
            <HeaderViewSubTitleTxt>
                Link Clicks : 
                <span style={{fontWeight:'bold',fontSize:18}}>{" " + myAffiliateLinkInfoData.total_link_clicks}</span>
            </HeaderViewSubTitleTxt>
            <HeaderViewSubTitleTxt>
                Balance : 
                <span style={{fontWeight:'bold',fontSize:18}}>{" " + printPrice(myAffiliateLinkInfoData.current_earnings_balance)}</span>
            </HeaderViewSubTitleTxt>
        </HeaderViewSidesDiv>
        <TabMainDiv>
            <TabMainSelectedView style={{left:(selectedTab == 0 ? "0%" : (selectedTab == 1 ? "33.3%" : "66.6%"))}}/>
            <TabTxt isSelected={selectedTab == 0} onClick={() => setCurrentSelectedTab(0)}>Sales</TabTxt>
            <TabTxt isSelected={selectedTab == 1} onClick={() => setCurrentSelectedTab(1)}>Products</TabTxt>
            <TabTxt isSelected={selectedTab == 2} onClick={() => setCurrentSelectedTab(2)}>Withdraws</TabTxt>
        </TabMainDiv>
    </HeaderViewDiv>

}





const Divider = styled.div`
   height: 3px;
   background-color: ${COLORS.colorLightGray};
   margin: 20px 0px 0px 0px;
`;






function MyAffiliateSalesDashBoard({siteDetails,setMyAffiliateLinkInfo,setIsLoading}) {
    

    const apiClient = useApi();
    const navigate = useNavigate();

    const appState = useLocalStateManager("myAffiliateSalesDashBoard","");

    const [myAffiliateLinkInfoData,setMyAffiliateLinkInfoData] = useState(appState.getState("page_data") || null);

    const [refreshData,setRefreshData] = useState(myAffiliateLinkInfoData == null);

    const userHasLoggedIn = hasLoggedIn();

    const [errorTxt,setErrorTxt] = useState();

    const [selectedTab,setCurrentSelectedTab] = useState(appState.getState("selectedTab") || 0);

    useEffect(() => {

        if (userHasLoggedIn) {
            generateSeoMetaTags(siteDetails.site_header_title + " - My Affiliate Sales Dashboard",null,null);
        } else {
            navigate("/?siteId=" + siteDetails.site_id);
        }

    },[]);


    useEffect(() => {

        if (refreshData && userHasLoggedIn) {

            apiClient.getFull(
                "/shop_site/web/get_or_create_my_affiliate_link_info_details",
                {site_id:siteDetails.site_id},
                resp => {
                    
                    appState.setState("page_data",resp);
                    setMyAffiliateLinkInfoData(resp);
                    setMyAffiliateLinkInfo(resp);
                    setRefreshData(false);
                    setIsLoading(false);

                },error => {
                    setErrorTxt(error);
                    setRefreshData(false);
                    setIsLoading(false);
                });

            setErrorTxt(null);
            setIsLoading(true);
        }

    },[refreshData]);



    let pageContent;


    if (myAffiliateLinkInfoData) {
        pageContent = <Fragment>
            {HeaderView(myAffiliateLinkInfoData,selectedTab,(tabIndex) => {setCurrentSelectedTab(tabIndex);appState.setState("selectedTab",tabIndex)})}
            {selectedTab == 0 && <SalesItemsListComponent salesOrderItems={myAffiliateLinkInfoData.sales_order_items}/>}
            {selectedTab == 1 && <SalesProductStats productSales={myAffiliateLinkInfoData.product_sales}/>}
            {selectedTab == 2 && <WithdrawsComponent myAffiliateLinkInfoData={myAffiliateLinkInfoData}/>}
            <Divider/>
        </Fragment>
    } else if (refreshData) {
        pageContent = <LoadingComponent/>
    } else if (errorTxt) {
        pageContent = <ErrorOrLoadingContainer>
             <ApiErrorDiv error={errorTxt} refreshPage={() => setRefreshData(true)} refreshButtonText="Retry Again"/>
        </ErrorOrLoadingContainer>
    }



    return <MainDiv>
        {pageContent}
    </MainDiv>

}


export default MyAffiliateSalesDashBoard;