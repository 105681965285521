import styled from "styled-components";
import useImageLoader from "../utils/ImageLoader";
import { COLORS } from "../utils/Theme";
import { printPriceOnly } from "../utils/CommonMethods";
import { useRef, useState } from "react";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import useAppConstants from "../utils/AppConstants";
import AddToCartOrBuyNowModal, { CART_MODE } from "./shoppingCart/AddToCartOrBuyNowModal";
import LoginOrSignUpComponent from "./shared/LoginOrSignUpComponent";


const ProductsGridView = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0px;
    column-gap: 10px;
    margin: 0px 8px;
`;



const ProductDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 5px 0px;
    padding: 0px 0px 8px 0px;
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
`;



const SoldOutBanner = styled.h4`
   font-size: 11px;
   font-weight: bold;
   border-radius: 3px;
   background-color: ${COLORS.colorInstagramMaroon};
   color: white;
   position: absolute;
   z-index: 5;
   top: 8px;
   left: 8px;
   padding: 2px 5px;
`;




const ProductImg = styled.img`
   flex-grow:1;
   width: 100%;
   display: block;
   object-fit:cover;
   border-radius: 8px;
`;




const ProductNameOrCode = styled.h3`
   padding: 9px 0px 2px 8px;
   font-size: 13px;
   color: ${COLORS.colorInstagramBlack};
   font-weight: normal;
   display: flex;
   justify-content: space-between;
   position: relative;
`;




const ProductPrice = styled.h3`
   margin: 0px 0px 0px 8px;
   font-size: 15px;
   color: ${COLORS.colorInstagramBlack};
   font-weight: normal;
`;



const ProductColorsContainerDiv = styled.div`
   margin: 5px 0px 0px 7px;
   display: flex;
   flex-direction: row;
   overflow-x:hidden;
`;

const ProductColorDiv = styled.div`
   box-sizing: border-box;
   padding:3px;
   width: 30px;
   height: 30px;
   margin-left: 0px;
   margin-right: 6px;
   border: ${props => props.selected ? ("2px solid " + COLORS.colorInstagramBlack) : "transparent"};
   border-radius: 50%;
   transition: all .15s ease-in;
   cursor: pointer;
   flex-shrink: 0;
`;

const ProductColor = styled.div`
   width: 100%;
   height: 100%;
   border-radius: 50%;
   background-color: ${props => props.color};
`;





function ProductItemDiv({siteProduct,affiliateLinkId,imageLoader,productIdToCoverImgMap,navigate,subCategoryId,setAddToCartProduct}) {

    const productData = siteProduct.product_data;
    const {product_label_code,cover_img} = productData;
    
    const [productCoverMediaItem,setProductCoverMediaItem] = useState(productIdToCoverImgMap.get(productData.product_id) || productData.focused_cover_img || cover_img); 

 
    let colorsDiv;
 
    if (productData.product_colors && productData.product_colors.length > 1) {
       colorsDiv = (<ProductColorsContainerDiv>
                    {productData.product_colors
                      .filter((itm,idx) => idx < 4) 
                      .map(color => 
                         <ProductColorDiv 
                            key={productData.product_id + "_ " + color.color_id} 
                            selected={color.media_items_ids.includes(productCoverMediaItem.media_id)}
                            onClick={e => {
                               e.stopPropagation();
                               productIdToCoverImgMap.set(productData.product_id,color.cover_img_media_item);
                               setProductCoverMediaItem(color.cover_img_media_item);
                            }}>
                         <ProductColor color = {color.hex_value}/>
                     </ProductColorDiv>)}
       </ProductColorsContainerDiv>);
    }else {
       colorsDiv = <></>
    }


    return <ProductDiv onClick={() => navigate("/productDetails?siteId="+ siteProduct.site_id + "&siteProductId=" + siteProduct.site_product_id + (subCategoryId ? "&subCategoryId=" + subCategoryId : "") + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : ""))}>
        {(productData.product_stock_amount_info?.available_for_sale_products == 0) && <SoldOutBanner>SOLD OUT</SoldOutBanner>}
        <ProductImg src={imageLoader.getUrlForImg(400,productCoverMediaItem.media_data)}/>
        <ProductNameOrCode>
           {product_label_code}
           <AddShoppingCartIcon
               onClick={(e) => {setAddToCartProduct(siteProduct);e.stopPropagation();}} 
               style={{zIndex:1,position:'absolute',top:0,right:0,padding:"8px 8px 5px 10px",fontSize:18}} />
        </ProductNameOrCode>
        <ProductPrice>{"Tsh " + printPriceOnly(siteProduct.price_info.price_retail_tzs)}</ProductPrice>
        {colorsDiv}
    </ProductDiv>

}





function ProductsListsGrid({siteDetails,siteProducts,navigate,subCategoryId,setRefreshShoppingCartItemsCountRandomLong,affiliateLinkId}) {

    const imageLoader = useImageLoader();
    const productIdToCoverImgMapRef = useRef(new Map());
    const appConstants = useAppConstants();

    const [addToCartProduct,setAddToCartProduct] = useState();

    return <ProductsGridView>
            {siteProducts.map(siteProduct => <ProductItemDiv key={siteProduct.site_product_id} siteProduct={siteProduct} affiliateLinkId={affiliateLinkId} imageLoader={imageLoader} productIdToCoverImgMap={productIdToCoverImgMapRef.current} navigate={navigate} subCategoryId={subCategoryId} setAddToCartProduct={setAddToCartProduct}/>)}
            {setRefreshShoppingCartItemsCountRandomLong && addToCartProduct && (appConstants.hasLoggedIn() ? <AddToCartOrBuyNowModal openingMode={CART_MODE} siteProductId={addToCartProduct.site_product_id} sourceProductId={addToCartProduct.source_product_id} onAddToCart={() => {setAddToCartProduct(null);setRefreshShoppingCartItemsCountRandomLong(Date.now());}} onCloseModal={() => setAddToCartProduct(null)}/> : <LoginOrSignUpComponent siteDetails={siteDetails} affiliateLinkId={affiliateLinkId} onLoginSucced={() => setRefreshShoppingCartItemsCountRandomLong(Date.now())} onCancelled={() => setAddToCartProduct(null)}/>)}
    </ProductsGridView>

}


export default ProductsListsGrid;