import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import useApi from "../../utils/Api";
import { Fragment, useEffect, useState,useRef } from "react";
import useImageLoader from "../../utils/ImageLoader";
import { printPriceCustom } from "../../utils/CommonMethods";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ImageViewerComponent from "../shared/ImageViewerComponent";
import { COLORS } from "../../utils/Theme";
import { LoadingSpinner } from "../../App";



const Modal = styled.div`
   position: fixed;
   width: 100vw;
   height: 100vh;
   top: 0px;
   left: 0px;
   background: rgba(0,0,0,.6);
   z-index: 200;
   display: flex;
   justify-content: center;
   align-items: flex-end;
`;




const MainDiv = styled.div`
   width: 100%;
   max-width: 600px;
   max-height: calc(100vh - 100px);
   min-height: 250px;
   padding: 20px 16px;
   background-color: white;
   display: flex;
   flex-direction: column;
   position: relative;
   overflow-y: auto;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
   border-bottom-left-radius: 0px;
   border-bottom-right-radius: 0px;
`;


const CloseIcnSpan = styled.span`
    position: absolute;
    right: 16px;
    top: 20px;
    width: 36px;
    height: 36px;
    background-color: ${COLORS.colorLightGrayLighter};
    border-radius: 6px;
    color: ${COLORS.colorInstagramBlack};
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;





const ProductItemInfoDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 10px;
`;


const ProductItemCoverImgParentView = styled.div`
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: ${COLORS.colorLightGray};
`;



const ProductItemCoverImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 5px;
`;


const ProductItemNameTitlePriceDiv = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 40px;
`;


const ProductTitle = styled.h3`
   font-size:  12px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;






const ProductItemPriceView = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-top: 2px;
   margin-bottom: 0px;
`;



const ProductItemPrice = styled.h1`
   font-size: 13px;
   font-weight: bold;
   color: ${COLORS.colorYellowGold};
`;







const ErrorView = styled.h3`
   margin-top: 10px;
   margin-bottom: 0px;
   color: white;
   background-color: darkred;
   border-radius: 3px;
   padding: 10px 15px;
   text-align: center;
   font-size: 13px;
   font-weight: normal;
`;






const ScrollView = styled.div`
   display: flex;
   flex-direction:column;
   align-items: stretch;
   overflow-y: auto;
`;






const ProductGroupItemView = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   background-color: ${COLORS.colorLightGrayLighter};
   padding: 10px 10px;
   border-radius: 8px;
   margin: 7.5px 0px;
`;


const ProductGroupItemCoverImgView = styled.div`
   display: flex;
   flex-direction: column;
   width: 65px;
   height: 65px;
   border-radius: 5px;
   margin-left: 0px;
   margin-right: 12px;
   background-color: ${COLORS.colorLightGray};
`;


const ProductGroupItemCoverImg = styled.img`
   width: 100%;
   height: 100%;
   border-radius: 5px;
`;




const ProductGroupItemInfoView = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
   justify-content: center;
`;



const ProductGroupStockAmountTxt = styled.h3`
   font-size: 13px;
   font-weight: normal;
   color: ${COLORS.colorGray};
   margin: 0px 0px;
`;




const ProductGroupItemTitleText = styled.h3`
   font-size: 13px;
   font-weight: normal;
`;


const ProductGroupItemPriceToQuantityView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ProductGroupBagItemPrice = styled.h2`
   font-size: 15px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;


const ProductGroupQuantityView = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
`;


const ProductGroupQuantityAmountView = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3px 8px;
    margin: 0px 3px;
    background-color: ${COLORS.colorWhite};
    border-radius: 3px;
`;



const ProductGroupQuantityAmount = styled.h3`
    color: ${COLORS.colorInstagramBlack};
    font-size: 13px;
    font-weight: bold;
`;



export function CreateProductVarGroupItemView(productVarGroupItem,siteProduct,productData,isRetailPrice,setDummyState,imageClient,varGroupIdToQuantityMap,setShowImageViewer) {


    const varGroupId = productVarGroupItem.var_group_id;
    const quantity = varGroupIdToQuantityMap.get(varGroupId);


    function onChangeQuantityAmount(add) {
        if (add) {
            if (quantity >= productVarGroupItem.var_group_stock_amount_info.available_for_sale_products) {
                return;
            }
            varGroupIdToQuantityMap.set(varGroupId,quantity + 1);
        } else if (quantity >= 1) {
            varGroupIdToQuantityMap.set(varGroupId,quantity - 1);
        } else {
            return;
        }
        setDummyState(Date.now());
    }


    let coverImgData;
    if (productVarGroupItem.display_media_items && productVarGroupItem.display_media_items.length > 0) {
        coverImgData = productVarGroupItem.display_media_items[0].media_data;
    }else {
        coverImgData = productData.cover_img.media_data;
    }

    let itemTitleElement;
    if (productVarGroupItem.is_none) {
        itemTitleElement = <div style={{width:0,height:10,backgroundColor:"transparent"}}/>
    } else {
       itemTitleElement = <ProductGroupItemTitleText style={{color:COLORS.colorGray}}>{productVarGroupItem.var_group_full_title}</ProductGroupItemTitleText>
    }
  


    return <ProductGroupItemView key={"product_group_var_item_" + productVarGroupItem.var_group_id}>
            <ProductGroupItemCoverImgView onClick={() => setShowImageViewer(coverImgData)}>
                <ProductGroupItemCoverImg src={imageClient.getUrlForImg(100,coverImgData)}/>
            </ProductGroupItemCoverImgView>
            <ProductGroupItemInfoView>
                {itemTitleElement}
                <ProductGroupStockAmountTxt>
                    {"Stock : "}
                    <span style={{fontWeight:'bold',color:COLORS.colorInstagramBlack}}>{productVarGroupItem.var_group_stock_amount_info.available_for_sale_products}</span>
                </ProductGroupStockAmountTxt>
                <ProductGroupItemPriceToQuantityView>
                    <ProductGroupBagItemPrice>
                       {isRetailPrice && printPriceCustom(siteProduct.price_info.price_retail_tzs,"TSh")}
                       {!isRetailPrice && printPriceCustom(siteProduct.price_info.price_wholesale_tzs,"TSh")}
                    </ProductGroupBagItemPrice>
                    <ProductGroupQuantityView>
                        <RemoveIcon onClick={e => onChangeQuantityAmount(false)} style={{color:COLORS.colorInstagramBlack,width:"22px",height:"22px",padding:"1px",cursor:"pointer"}}/>
                        <ProductGroupQuantityAmountView>
                            <ProductGroupQuantityAmount>{quantity}</ProductGroupQuantityAmount>
                        </ProductGroupQuantityAmountView>
                        <AddIcon onClick={e => onChangeQuantityAmount(true)} style={{color:COLORS.colorInstagramBlack,width:"22px",height:"22px",padding:"1px",cursor:"pointer"}}/>
                    </ProductGroupQuantityView>
                </ProductGroupItemPriceToQuantityView>
            </ProductGroupItemInfoView>
         </ProductGroupItemView>

}












const LoadingDataContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;





const BigButton = styled.button`
   width: 100%;
   height: 46px;
   border-radius: 23px;
   background-color: ${COLORS.colorInstagramBlue};
   border-style: none;
   color: ${COLORS.colorWhite};
   font-size: 14px;
   font-weight: bold;
   margin-top: 15px;
   margin-bottom: 10px;
   opacity: ${props => props.disabled ? .3 : 1};
   flex-shrink: 0;
`;





export const CART_MODE="CART_MODE",BUY_MODE="BUY_MODE";


function AddToCartOrBuyNowModal({siteProductId,sourceProductId,openingMode,onAddToCart,onCloseModal,setOrderCheckoutParams}) {

    const apiClient = useApi();
    const imageClient = useImageLoader();

    const [apiData,setApiData] = useState();
    
    const [showImageViewer,setShowImageViewer] = useState();

    const [apiReqId,setApiReqId] = useState();
    const setRandomState = useState()[1];

    const varGroupIdToQuantityMapRef = useRef(new Map()); // varGroupId,quantity

    const [errorTxt,setErrorTxt] = useState();


    useEffect(() => {

        let reqId = apiClient.getFull(
            "/shop_site/web/init_wholesale_shopping_cart",
            {site_product_id:siteProductId,source_product_id:sourceProductId},
            resp => {

                const group_variations = resp.group_variations
                                             .filter(varGroup => varGroup.var_group_stock_amount_info.available_for_sale_products > 0);
                resp.group_variations = group_variations;

                varGroupIdToQuantityMapRef.current.clear();

                for (const varGroup of group_variations) {
                    varGroupIdToQuantityMapRef.current.set(varGroup.var_group_id,varGroup.quantity);
                }

                setApiData(resp);
                setApiReqId(null);
            },
            error => {
                console.log(error);
                onCloseModal();
            });
        
        setApiReqId(reqId);

    },[]);

    let centerContent;

    function addToCart() {


        const bag_items = [];
        for (const varGroup of apiData.group_variations) {
            const var_group_id = varGroup.var_group_id;
            const quantity = varGroupIdToQuantityMapRef.current.get(var_group_id);
            if (quantity > 0) {
                bag_items.push({var_group_id,quantity});
            }
        }
         
        const reqId = apiClient.postJson(
           "/shop_site/web/add_product_to_shopping_bag_wholesale",
           {site_id:apiData.site_product.site_id,site_product_id:siteProductId,source_product_id:sourceProductId,bag_items},
           resp => {
             onAddToCart();
             onCloseModal();
           },error => {
             setApiReqId(null);
             setErrorTxt(error);
           });

        setApiReqId(reqId);
        setErrorTxt(null);

    }


    function buyNow() {

        const order_items = [];
        for (const varGroup of apiData.group_variations) {
            const var_group_id = varGroup.var_group_id;
            const quantity = varGroupIdToQuantityMapRef.current.get(var_group_id);
            if (quantity > 0) {
                order_items.push({var_group_id,quantity});
            }
        }
        setOrderCheckoutParams({site_id:apiData.site_product.site_id,order_items});
        onCloseModal();
        
    }


    if (apiData) {

        const {site_product,product,group_variations} = apiData;

        let totalPrice = 0;
        let totalQuantity = 0;
        for (const groupVariation of group_variations) {
            totalQuantity+=varGroupIdToQuantityMapRef.current.get(groupVariation.var_group_id);
        }
        
        const isRetailPrice = totalQuantity < 6;


        for (const groupVariation of group_variations) {
            const priceInfo = site_product.price_info;
            const price = isRetailPrice ? priceInfo.price_retail_tzs : priceInfo.price_wholesale_tzs
            totalPrice+=price * varGroupIdToQuantityMapRef.current.get(groupVariation.var_group_id);
        } 


        centerContent = <Fragment>
            <CloseIcnSpan onClick={() => onCloseModal()}>
                <CloseIcon style={{fontSize: "25"}} onClick={e => onCloseModal()}/>
            </CloseIcnSpan>
            <ProductItemInfoDiv>
                <ProductItemCoverImgParentView style={{height:45,width:45,borderRadius:2}} onClick={() => setShowImageViewer(product.cover_img.media_data)}>
                   <ProductItemCoverImg src={imageClient.getUrlForImg(60,product.cover_img.media_data)}/>
                </ProductItemCoverImgParentView>
                <ProductItemNameTitlePriceDiv>
                    <ProductTitle>{product.product_label_code}</ProductTitle>
                    <ProductItemPriceView>
                      <ProductItemPrice>
                        <span style={{color:COLORS.colorGray,fontWeight:'normal'}}>Quantity : </span>
                        {totalQuantity}
                      </ProductItemPrice>
                      <div style={{backgroundColor:COLORS.colorLightGrayLighter,height:12,width:1,marginLeft:6,marginRight:6}}/>
                      <ProductItemPrice>
                        <span style={{color:COLORS.colorGray,fontWeight:'normal'}}>Total : </span>
                        {printPriceCustom(totalPrice,"TSh")}
                      </ProductItemPrice>
                    </ProductItemPriceView>
                </ProductItemNameTitlePriceDiv>
            </ProductItemInfoDiv>
            <ScrollView>
               {group_variations.map(productGroupVar => CreateProductVarGroupItemView(productGroupVar,site_product,product,isRetailPrice,setRandomState,imageClient,varGroupIdToQuantityMapRef.current,setShowImageViewer))}
            </ScrollView>
            {errorTxt && <ErrorView>{errorTxt}</ErrorView>}
            <BigButton
               onClick={openingMode === CART_MODE ? addToCart : buyNow}
               disabled={apiReqId || totalQuantity === 0}>
                {openingMode === CART_MODE ? "Add to Cart" : "Buy Now"}
            </BigButton>
        </Fragment>

    } else {
        centerContent = <LoadingDataContainer>
            <LoadingSpinner/>
        </LoadingDataContainer>
    }



    return <Modal onClick={e => {onCloseModal();e.stopPropagation()}}>
        <MainDiv onClick={e => e.stopPropagation()}> 
          {centerContent}
          {showImageViewer && <ImageViewerComponent images={[showImageViewer]} onCloseModal={() => setShowImageViewer(null)}/>}
        </MainDiv>
    </Modal>
}





export default AddToCartOrBuyNowModal;