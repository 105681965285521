import moment from "moment";




export function getMinutesFromStartOfDay(hour,minute) {
    return (hour * 60) + minute;
}


export function printPrice(priceInfo) {
    return priceInfo.currency + " " + Math.trunc(priceInfo.price).toLocaleString();
}


export function printPriceOnly(price) {
    return Math.trunc(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function printPriceCustom(price,currency) {
    return printPriceOnly(price) + " " + currency;
}


export function isSafariBrowser() {
    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') === -1 &&
               navigator.userAgent.indexOf('FxiOS') === -1;

    return isSafari;           
}


export function generateSeoMetaTags(title,imageUrl,descrip) {
    document.title = title;
    document.querySelector('meta[property="og:title"]').setAttribute("content", title);
    document.querySelector('meta[property="og:url"]').setAttribute("content",window.location.href);
    document.querySelector('meta[property="og:site_name"]').setAttribute("content","Seperah Fashion");
    document.querySelector('meta[property="og:description"]').setAttribute("content",descrip);

    if (imageUrl) {
        let imageMeta = document.querySelector('meta[property="og:image"]');
        imageMeta.setAttribute("content",imageUrl);
        imageMeta.setAttribute("itemprop","image");

        let imageSecureMeta = document.querySelector('meta[property="og:image:secure_url"]');
        imageSecureMeta.setAttribute("content",imageUrl);
        imageSecureMeta.setAttribute("itemprop","image");
    }

    document.querySelector('meta[property="og:type"]').setAttribute("content","website");
    document.querySelector('meta[property="og:updated_time"]').setAttribute("content",Number(Date.now() + (5 * 60 * 1000))); // update every 5 minutes
}



export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  
export function convertMinutesToHoursMinFormat(totalMinutes) {
    let hours = Math.floor(totalMinutes / 60);          
    let minutes = totalMinutes % 60;
    return hours + ":" + ((minutes < 9) ? ("0" + minutes.toString()) : minutes);
}


export function copyTextToClipBoard(text) {
    var input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
}


export function formatTimeStamp(time) {
    return moment(new Date(time)).format('HH:mm');
}

export function formatDateTime(time) {
    return moment(new Date(time)).format('ddd, MMM Do YYYY [at] HH:mm');
}


export function formatDateTimeSimpler(time) {
    return moment(new Date(time)).format('ddd, MMM Do [at] HH:mm');
}


export function formatDate(time) {
    return moment(new Date(time)).format('dddd, MMMM Do YYYY');
}

export function formatDateInfo(dateInfo) {
    return moment({year:dateInfo.year, month:dateInfo.java_cal_month, day:dateInfo.date}).format('dddd, Do MMMM YYYY');
}

export function formatDateInfoSimple(dateInfo) {
    return moment({year:dateInfo.year, month:dateInfo.java_cal_month, day:dateInfo.date}).format('ddd, MMM D');
}




export function getAspectRationHeight(viewWidth,imageHeight,imageWidth) {
    return (viewWidth * imageHeight)/imageWidth
}


export function getAspectRationWidth(viewHeight,imageWidth,imageHeight) {
    return (viewHeight * imageWidth)/imageHeight
}