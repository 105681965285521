import styled from "styled-components";
import useWindowDimensions from "../../utils/CommonHooks";
import { COLORS } from "../../utils/Theme";
import { getAspectRationWidth } from "../../utils/CommonMethods";
import { useEffect, useRef, useState } from "react";
import ImageViewerComponent from "../shared/ImageViewerComponent";



const MainDiv = styled.div`
   display: flex;
   overflow-x: auto;
   margin: 10px 12px;

   &::-webkit-scrollbar {
       display: none;
   }
`;


const ImageView = styled.div`
   height: ${props => props.height}px;
   width: ${props => props.width}px;
   margin: 0px 10px 0px 0px;
   border-radius: 10px;
   padding: 3px;
   border: ${props => props.focused ? ("2.5px solid " + COLORS.colorInstagramBlack) : "none"};
   display: flex;
   flex-direction: column;
   justify-content: center;
`;


const Image = styled.img`
   height: ${props => props.height}px;
   width: ${props => props.width}px;
   border-radius: 7px;
   background-color: ${COLORS.colorLightGray};
   object-fit: fill;
`;



function ProductImagesComponent({productData,imageClient,currentFocusedMediaItems}) {

    const {height,width} = useWindowDimensions();
    const imageViewHeight = 250;

    const [clickedImageIndex,setClickedImageIndex] = useState(); // {index:0}

    const scrollViewRef = useRef();

    useEffect(() => {

        if (currentFocusedMediaItems && scrollViewRef.current) {
            for (let idx = 0; idx < productData.product_data.main_media_items.length; idx++) {
                const mediaItem = productData.product_data.main_media_items[idx];
                if (currentFocusedMediaItems.includes(mediaItem.media_id)) {
                    scrollViewRef.current.children[idx].scrollIntoView({ behavior: "smooth" });
                    break;
                }
            }
        }

    },[currentFocusedMediaItems]);


    return <MainDiv ref={scrollViewRef}>
        {productData.product_data
                    .main_media_items
                    .map((mediaItem,index) => {

                        const focused = currentFocusedMediaItems && currentFocusedMediaItems.includes(mediaItem.media_id);
                        const imgViewWidth = getAspectRationWidth(imageViewHeight,mediaItem.media_width,mediaItem.media_height);

                        return <ImageView focused={focused} onClick={() => setClickedImageIndex({index})} key={mediaItem.media_id} height={imageViewHeight} width={imgViewWidth}>
                            <Image height={imageViewHeight - 1} width={imgViewWidth - 6} src={imageClient.getUrlForImg(imgViewWidth,mediaItem.media_data)}/>
                        </ImageView>

                    })}
        {clickedImageIndex && <ImageViewerComponent images={productData.product_data.main_media_items.map(mediaItm => mediaItm.media_data)} clickedImageItemIndex={clickedImageIndex.index} onCloseModal={() => setClickedImageIndex(null)}/>}
    </MainDiv>


}


export default ProductImagesComponent;