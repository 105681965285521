

const stateManagerMap = new Map();

export function clearAllData() {
    stateManagerMap.clear();
}

function useLocalStateManager(componentId,itemId) {

    let compomentDataKey = componentId + "__" + itemId;

    if (!stateManagerMap.has(compomentDataKey)) {
        stateManagerMap.set(compomentDataKey,{}); // save the component state object
    }

    let stateManager = {
        setState(stateKey,stateValue) {
            stateManagerMap.get(compomentDataKey)[stateKey] = stateValue;
        },getState(stateKey) {
            return stateManagerMap.get(compomentDataKey)[stateKey];
        },deleteState(stateKey) {
            delete stateManagerMap.get(compomentDataKey)[stateKey];
        },hasState(stateKey) {
            let stateValue = stateManagerMap.get(compomentDataKey)[stateKey];
            return stateValue !== undefined && stateValue !== null;
        },getStateOrDef(stateKey,defValue) {
            return this.hasState(stateKey) ? this.getState(stateKey) : defValue;
        }
    };

    return stateManager;

}


export default useLocalStateManager;



