import axios from "axios";
import { useEffect, useRef } from "react";
import { loadUserJson, setUserJson } from "./AppConstants";
import { clearAllData } from "./LocalStateManager";
import { DeviceUUID } from "device-uuid";


const device_id = new DeviceUUID().get();

const initHeaders = {'is_web': Date.now(),visitor_id:device_id};



export function refreshUserJsonAndUserData(siteId) {

    const storageKey = "apiAuth__" + siteId;
    let apiAuth = localStorage.getItem(storageKey) && JSON.parse(localStorage.getItem(storageKey));
    resetAxiosAuthHeaders(apiAuth);
    loadUserJson(siteId);

}


function resetAxiosAuthHeaders(apiAuth) {

    if (apiAuth) {
        axios.defaults.headers = {
            ...initHeaders,
            customer_login_key:apiAuth.login_key,
            customer_uid:apiAuth.uid
         };
     }else {
        axios.defaults.headers = initHeaders;
     }

}


export function setApiAuth(siteId,apiAuth) {
    clearAllData();
    resetAxiosAuthHeaders(apiAuth);
    const storageKey = "apiAuth__" + siteId;
    if (apiAuth) {
       localStorage.setItem(storageKey,JSON.stringify(apiAuth));
    }else {
       localStorage.removeItem(storageKey);
    }
}



export const isTestInMode = false;


export const baseURL = isTestInMode ? 'https://fluffy-trifle-e175d8.netlify.app/apiTest/' : 'https://fluffy-trifle-e175d8.netlify.app/api/';


axios.defaults.baseURL = baseURL;
axios.defaults.headers = initHeaders;
axios.defaults.timeout = 30000;

const ERROR_OK = 0;
const ERROR_USER_AUTHENTICATION = 10;
const ERROR_NO_PERMISSIONS = 20;
const ERROR_READ_DATABASE = 30;
const ERROR_WRITE_DATABASE = 40;
const ERROR_FILE = 50;
const ERROR_API_PARAMETERS = 60;
const ERROR_OPERATION_FAILED = 70;
const ERROR_UNKNOWN = 80;
const ERROR_USER_AUTHENTICATION_EXPIRED_APIAUTH = 100;
const ERROR_USER_AUTHENTICATION_SUSPENDED_APIAUTH = 110;
const ERROR_USER_AUTHENTICATION_USER_LOGGED_OUT_APIAUTH = 120;
const ERROR_PHONE_NUMBER_REQUIRED = 130;  



// onResponse -> returns to resp json
// onFailed -> returns the error string;
function processApiResponse(response,onResponse,onFailed) {
    if (response.status === 200) {
        let data = response.data;
        if (data.code === ERROR_OK) {
            onResponse(data.resp);
        }else {
            onFailed(getApiErrorString(data));
        }
   }else {
       onFailed("Unknown Error");
   }
} 

function processErrorResponse(error,onFailed) {
   if (axios.isCancel(error)) {
     console.log("Request Cancelled");
   } else { 
     console.log(error);
     onFailed("Failed to connect to Server");
   }
}


function getApiErrorString(json) {
    let displayMessage;
    let code = json.code;

    switch (code){
        case ERROR_USER_AUTHENTICATION:
        case ERROR_USER_AUTHENTICATION_EXPIRED_APIAUTH:
        case ERROR_USER_AUTHENTICATION_SUSPENDED_APIAUTH:
        case ERROR_USER_AUTHENTICATION_USER_LOGGED_OUT_APIAUTH:{

            if (code === ERROR_USER_AUTHENTICATION_EXPIRED_APIAUTH) {
                displayMessage = "Your authentication token has expired and you need to login again";
            }else if (code === ERROR_USER_AUTHENTICATION_SUSPENDED_APIAUTH){
                displayMessage = "This authentication session has been suspended. Please login again to proceed";
            } else if (code === ERROR_USER_AUTHENTICATION_USER_LOGGED_OUT_APIAUTH) {
                displayMessage = "You already logged out from this session. Please login again";
            }else {
                displayMessage = "Invalid username and/or password";
            }

            //TODO -> Should navigate to login page now.....
            setUserJson(null);
            setApiAuth(null);

            break;
        }
        case ERROR_NO_PERMISSIONS : {
            displayMessage = "You have no permissions for this operation";
            break;
        }
        case ERROR_READ_DATABASE : {
            displayMessage = "Database read error";
            break;
        }
        case ERROR_WRITE_DATABASE : {
            displayMessage = "Failed to write to database"
            break;
        }
        case ERROR_FILE : {
            displayMessage = "Invalid File";
            break;
        }
        case ERROR_API_PARAMETERS : {
            displayMessage = "Parameters Error"
            break;
        }
        case ERROR_OPERATION_FAILED : {
            displayMessage = json.msg ? json.msg : "Operation Failed";
            break;
        }
        case ERROR_UNKNOWN : {
            displayMessage = "Unknown Error";
            break;
        }
        case ERROR_PHONE_NUMBER_REQUIRED: {
            // TODO -> Go to phone number adding page
            displayMessage = "You need to attach your phone number so as to continue";
            break;
        }
        default: {
            displayMessage = "Unknown Error";
        }
    }

    return displayMessage;
}


export function getFullApi(apiPath,params,onResponse,onFailed) {
    const controller = new AbortController();
    axios(apiPath, {
      method:"get",
      params: params, 
      signal: controller.signal
    }).then(response => processApiResponse(response,onResponse,onFailed))
      .catch(error => processErrorResponse(error,onFailed));

    return controller;
} 

export function getSimpleApi(apiPath,onResponse,onFailed) {
    return getFullApi(apiPath,undefined,onResponse,onFailed);
}


export function postJsonApi(apiPath,postJson,onResponse,onFailed) {
    const controller = new AbortController();
    axios(apiPath,{
        method:"post",
        data: postJson,
        signal:controller.signal,
        headers: {"Content-Type": "application/json"} 
    }).then(response => processApiResponse(response,onResponse,onFailed))
      .catch(error => processErrorResponse(error,onFailed));

    return controller;
}


export function postFormApi(apiPath,postJson,filesObj,uploadProgress,onResponse,onFailed) {

    var bodyFormData = new FormData();
    bodyFormData.append('post_json', JSON.stringify(postJson));

    if (filesObj) {
        for (let field in filesObj) {
            bodyFormData.append(field,filesObj[field]);
        }
    }

    const controller = new AbortController();

    axios(apiPath, {
        method:"post",
        data: bodyFormData,
        signal:controller.signal,
        onUploadProgress: uploadProgress, // uploadProgress -> has 'total' (total bytes) and 'loaded' which is current uploaded
        headers: { "Content-Type": "multipart/form-data" }
    }).then(response => processApiResponse(response,onResponse,onFailed))
      .catch(error => processErrorResponse(error,onFailed));

    return controller;
}





function useApi() {

    let requestsMapRef = useRef(new Map()); // map to store all the components connections

    useEffect(() => {

        let requestsMap = requestsMapRef.current;

        return () => {
            for (let controller of requestsMap.values()) {
                controller.abort();
            }
            requestsMap.clear();
        };
    },[]);

    function getFullImpl(apiPath,params,onResponse,onFailed) {
        let requestId = Date.now();
        let controller = getFullApi(apiPath,params,
            resp => {
                requestsMapRef.current.delete(requestId);
                onResponse(resp);
            },error => {
                onFailed(error);
                requestsMapRef.current.delete(requestId);
            });
        requestsMapRef.current.set(requestId,controller);
        return requestId;    
    }

    let apiClient = {
        cancelRequest(requestId) {
            let requestController = requestsMapRef.current.get(requestId);
            if (requestController) {
                requestController.abort();
                requestsMapRef.current.delete(requestId);
            }
        },
        getSimple(apiPath,onResponse,onFailed) {
           return getFullImpl(apiPath,undefined,onResponse,onFailed);
        },
        getFull(apiPath,params,onResponse,onFailed) {
           return getFullImpl(apiPath,params,onResponse,onFailed);
        },
        postJson(apiPath,postJson,onResponse,onFailed) {
            let requestId = Date.now();
            let controller = postJsonApi(apiPath,postJson,
                resp => {
                    requestsMapRef.current.delete(requestId);
                    onResponse(resp);
                },error => {
                    requestsMapRef.current.delete(requestId);
                    onFailed(error);
                });
            requestsMapRef.current.set(requestId,controller);
            return requestId; 
        },
        postForm(apiPath,postJson,filesObj,uploadProgress,onResponse,onFailed) {
            let requestId = Date.now();
            let controller = postFormApi(apiPath,postJson,filesObj,uploadProgress,
                resp => {
                    requestsMapRef.current.delete(requestId);
                    onResponse(resp);
                },error => {
                    requestsMapRef.current.delete(requestId);
                    onFailed(error);
                });
            requestsMapRef.current.set(requestId,controller);
            return requestId; 
        }
    };

    return apiClient;

}


export default useApi;