import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, useRef, useState } from "react";
import useApi, { setApiAuth } from "../../utils/Api";
import { setUserJson } from "../../utils/AppConstants";
import { COLORS } from "../../utils/Theme";



const ModalDiv = styled.div`
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0px;
   left:0px;
   background-color: rgba(0,0,0,.8);
   z-index: 400;
   display: flex;
   justify-content: center;
   align-items:center;
   overflow-y: hidden;
`;


const ParentDiv = styled.div`
    position: relative;
    width: 100%;
    max-width: 400px;
    max-height: 95%;
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.colorWhite};
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px 15px;
`;



const HeaderDiv = styled.div`
   width: 100%;
   height: 60px;
   position: relative;
   flex-shrink: 0;
   background-color: ${COLORS.colorInstagramBlack};
   display: flex;
   align-items: center;
   justify-content: center;
`;


const HeaderCloseIcnSpan = styled.span`
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    border-radius: 6px;
    color: ${COLORS.colorWhite};
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;


const HeaderText = styled.h2`
    margin: 0px;
    padding: 0px;
    color: ${COLORS.colorWhite};
    font-size: 16px;
`;



const ApiErrorLabel = styled.label`
   padding: 10px 20px;
   font-size: 14px;
   font-weight: normal;
   background-color: red;
   color: ${COLORS.colorWhite};
   text-align: center;
`;







const LoginOrSignUpDiv = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 10px;
   margin-bottom: 20px;
   margin-left: 20px;
   margin-right: 20px;
`;


const InputLabel = styled.label`
   margin-top: 15px;
   margin-bottom: 0px;
   margin-left: 0px;
   margin-right: 0px;
   color: ${COLORS.colorGray};
   font-size: 14px;
`;


const Input = styled.input`
   border:none;
   outline:none;
   border-radius: 5px;
   padding: 13px 16px;
   font-size: 16px;
   color: ${COLORS.colorInstagramBlack};
   background-color: rgb(232, 240, 254);
   margin-top: 15px;
   margin-bottom: 0px;

   &::placeholder {
       color: ${COLORS.colorGray};
   }

   &:focus {
       border: 2px solid ${COLORS.colorInstagramBlack};
       border-radius: 5px;
   }

   &::-ms-reveal {
       filter: invert(100%);
   }

`;



const AddAPasswordTxt = styled.h3`
    margin: 5px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
    font-size: 13px;
    color: ${COLORS.colorGray};
    font-weight: normal;
`;


const ReccomendPasswordTxt = styled.h3`
    margin: 10px 0px 0px 0px;
    padding: 8px 6px;
    font-size: 12px;
    color: ${COLORS.colorWhite};
    font-weight: normal;
    background-color: ${COLORS.colorInstagramPurpleViolet};
    border-radius: 3px;
    text-align: center;
`;





const SubmitButton = styled.button`
   width: 100%;
   height: 46px;
   border-radius: 23px;
   background-color: ${COLORS.colorInstagramBlue};
   border-style: none;
   color: ${COLORS.colorWhite};
   font-size: 16px;
   font-weight: bold;
   margin-top: 25px;
   margin-bottom: 10px;
   opacity: ${props => props.isSubmitting ? .3 : 1};
`;






function LoginOrSignUpComponent({siteDetails,affiliateLinkId,onLoginSucced,onCancelled}) {

    const apiClient = useApi();
    const [showPasswordViews,setShowPasswordViews] = useState(false);
    const [isSubmitting,setIsSubmitting] = useState(false);
    const [formInputErrors,setFormInputErrors] = useState();

    const phoneNumberInputRef = useRef();
    const passwordInputRef = useRef();




    function submit() {
        if (!isSubmitting) {
    
            if (!tanzanianPhoneNumberRegex.test(phoneNumberInputRef.current.value)) {
                setFormInputErrors("Please enter a valid phone number. +255784xxxxxx");
                return;
            }

            const site_id = siteDetails.site_id;

            const postJson = {
                site_id,
                phone_number:phoneNumberInputRef.current.value,
                login_key:showPasswordViews ? passwordInputRef.current.value : "",
                affiliateLinkId
            };


            apiClient.postJson(
                "/shop_site/web/create_or_login_customer",
                postJson,
                resp => {
                    
                    setApiAuth(site_id,resp.apiAuth);
                    setUserJson(site_id,resp.userJson);
                    onLoginSucced();

                },error => {
                    setIsSubmitting(false);
                    setFormInputErrors(error);
                });
           

            setIsSubmitting(true);
            setFormInputErrors(null);

        }
    }



    return <ModalDiv>
        <ParentDiv onClick={e => e.stopPropagation()}>
            <HeaderDiv>
                {!isSubmitting && <HeaderCloseIcnSpan onClick={e => onCancelled()}><CloseIcon style={{fontSize: "25"}}/></HeaderCloseIcnSpan>}
                <HeaderText>{siteDetails.site_header_title}</HeaderText>
            </HeaderDiv>
            {formInputErrors && <ApiErrorLabel>{formInputErrors}</ApiErrorLabel>}
            <LoginOrSignUpDiv>
               <InputLabel>{"Phone Number (WhatsApp)"}</InputLabel>
               <Input ref={phoneNumberInputRef} type="tel" placeholder="Enter your phone number" />
               {!showPasswordViews && <Fragment>
                   <AddAPasswordTxt onClick={() => setShowPasswordViews(true)}>If you want to add a password. <span style={{fontWeight:'bold'}}>Click Here</span></AddAPasswordTxt>
                   <ReccomendPasswordTxt>If you are planning to join the sales affiliate program, we reccomend you add a password</ReccomendPasswordTxt>
                </Fragment>}
               {showPasswordViews 
                  && <Fragment>
                        <InputLabel>{"Password (Optional)"}</InputLabel>
                        <Input ref={passwordInputRef} type="password" placeholder="Enter your password" />
                    </Fragment>}
               <SubmitButton isSubmitting={isSubmitting} onClick={e => submit()}>{"Enter"}</SubmitButton>
            </LoginOrSignUpDiv>
        </ParentDiv>
    </ModalDiv>

}


export const tanzanianPhoneNumberRegex = /^\+255[0-9]{9}$/;


export default LoginOrSignUpComponent;