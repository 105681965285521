import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import nextIcn from "../../icons/next.svg";
import previousIcn from "../../icons/previous.svg";
import useImageLoader from "../../utils/ImageLoader";


const ModalDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: rgba(0,0,0,.9);
  display: flex;
  flex-direction: column;
`;


const HeaderDiv = styled.div`
   position: relative;
   width: 100%;
   height: 80px;
   display: flex;
   align-items: center;
   justify-content: center;
`;


const CurrentIndex = styled.h1`
   margin: 0px;
   padding: 0px;
   font-size: 20px;
   font-weight: normal;
   color: white;
`;


const CloseIcnSpan = styled.span`
    position: absolute;
    right: 30px;
    top: 20px;
    width: 40px;
    height: 40px;
    background-color: rgba(255,255,255,.1);
    border-radius: 6px;
    color: white;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width:600px) {
       right: 15px;
    }
`;


const SliderDiv = styled(Slider)`
    width: 100vw;
    height: calc(100vh - 240px);
`;

const Slide = styled.div`
    width: 100vw;
    height: calc(100vh - 240px);
`;


const SlideImg = styled.img`
   width: 100%;
   height: 100%;
   object-fit: contain;
`;

const NextArrowImg = styled.img`
  width: 20px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  padding: 15px;
  background-color: rgba(0, 0, 0, .8);
  z-index: 505;
  right: 0px;
  top: calc(((100vh - 240px)/2 + 80px) - 12px);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 8px;
`;

const PrevArrowImg = styled.img`
  width: 20px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  padding: 15px;
  background-color: rgba(0, 0, 0, .8);
  z-index: 505;
  left: 0px;
  top: calc(((100vh - 240px)/2 + 80px) - 12px);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;


function CreateImageSlider(images,imageClient,sliderRef,setCurrentImageIndex) {
    
      const settings = {
        dots: false,  
        infinite: false,
        lazyLoad: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: 0,
        autoplay: false,
        beforeChange: (current, next) => setCurrentImageIndex(true,next),
      };
    
      return (
        <SliderDiv ref={sliderRef} {...settings}>
            {images.map((image, idx) => (
              <Slide key ={idx}>
                  <SlideImg src={imageClient.getUrlForImg(1200,image)} />
              </Slide>
             ))}
          </SliderDiv>
      );
}


const SmallerImagesDiv = styled.div`
   width: calc(100% - 40px);
   margin: 40px 20px 0px 20px;
   display: flex;
   flex-wrap: nowrap;
   overflow-x: auto;
`;


const SmallerImage = styled.img`
   height: 80px;
   border-radius: 4px;
   border-color: none;
   object-fit: cover;
   margin: 5px 10px;
   transform: scale(${props => props.selected ? "1" : ".8"});
   transition: transform .2s ease-in;
   cursor: pointer;
`;



function CreateSmallerImagesDiv(images,imageClient,currentImageIndex, setCurrentImageIndex) {
    return <SmallerImagesDiv>
            <div style={{marginLeft:"auto",width:"0px",height:"0px",flexGrow:"0"}}/>
            {images.map((image,index) => 
              <SmallerImage key={index}
                   onClick={e => setCurrentImageIndex(false,index)} 
                   selected={index === currentImageIndex} 
                   src={imageClient.getUrlForImg(100,image)}/>)}
            <div style={{marginRight:"auto",width:"0px",height:"0px",flexGrow:"0"}}/>
        </SmallerImagesDiv>
}



function ImageViewerComponent({images,clickedImageItemIndex,onCloseModal}) {

    const [currentImageIndex,setCurrentImageIndexState] = useState(clickedImageItemIndex || 0);
    const sliderRef = useRef();
    const imageClient = useImageLoader();

    useEffect(() => {
        if (sliderRef.current && clickedImageItemIndex) {
            setTimeout(() => sliderRef.current.slickGoTo(clickedImageItemIndex,true));
        }
    },[clickedImageItemIndex])

    function setCurrentImageIndex(fromSlider,index) {
        if (fromSlider) {
            setCurrentImageIndexState(index);
        }else {
            sliderRef.current.slickGoTo(index);
        }
    }    

    
    return <ModalDiv onClick={e => e.stopPropagation()}>
        <HeaderDiv>
            <CloseIcnSpan><CloseIcon style={{fontSize: "25"}} onClick={(e) => onCloseModal()}/></CloseIcnSpan>
            <CurrentIndex>{(currentImageIndex + 1) + " / " + images.length}</CurrentIndex>
        </HeaderDiv>
        {(currentImageIndex > 0) && (images.length > 1) && <PrevArrowImg src={previousIcn} onClick={e => setCurrentImageIndex(false,currentImageIndex - 1)}/>}
        {(currentImageIndex < (images.length - 1)) && (images.length > 1) && <NextArrowImg src={nextIcn} onClick={e => setCurrentImageIndex(false,currentImageIndex + 1)}/>}
        {CreateImageSlider(images,imageClient,sliderRef,setCurrentImageIndex)}
        {(images.length > 1) && CreateSmallerImagesDiv(images,imageClient,currentImageIndex, setCurrentImageIndex)}
    </ModalDiv>

}



export default ImageViewerComponent;