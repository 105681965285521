import { useMemo } from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/Theme";
import { printPriceOnly } from "../../utils/CommonMethods";
import { canBuyShoppingBagItem } from "./ProductShoppingCartItemComponent";



const MainView = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   padding: 10px 20px 5px 20px;
`;



const MainViewSide = styled.div`
   display: flex;
   align-items: flex-end;
   flex-direction: row;
`;


const TotalAmountTitle = styled.h4`
   color: ${COLORS.colorGray};
   font-size: 12px;
   font-weight: normal;
   margin: 0px 0px 2px 0px;
`;



const TotalAmount = styled.h3`
   color: ${COLORS.colorInstagramBlack};
   font-size: 17px;
   font-weight: bold;
`;




function SalesShoppingBagSummaryComponent({randomState,shoppingCartList}) {


    const {totalPrice,totalProductsAmount,totalProductsQuantity} = useMemo(() => {

        const siteProductIds = new Set();
        let totalPrice = 0,totalProductsQuantity = 0;

        for (const shoppingCartItem of shoppingCartList) {

            let bagItemTotalProductQuantity = 0;
            const {shopping_items,site_product_data} = shoppingCartItem;

            for (const shoppingBagItem of shopping_items) {
                if (canBuyShoppingBagItem(shoppingBagItem,site_product_data) && shoppingBagItem.selected) {
                    siteProductIds.add(shoppingBagItem.site_product_id);
                    const quantity = shoppingBagItem.quantity;
                    bagItemTotalProductQuantity+=quantity;
                }
            }

            totalProductsQuantity+=bagItemTotalProductQuantity;

            const isRetailPrice = bagItemTotalProductQuantity < 6;

            for (const shoppingBagItem of shopping_items) {
                if (canBuyShoppingBagItem(shoppingBagItem,site_product_data) && shoppingBagItem.selected) {
                    const quantity = shoppingBagItem.quantity;
                    const price = isRetailPrice ? site_product_data.price_info.price_retail_tzs : site_product_data.price_info.price_wholesale_tzs;
                    totalPrice+=(quantity * price);
                }
            }

        }

        return {totalPrice,totalProductsAmount:siteProductIds.size,totalProductsQuantity};

    },[randomState,shoppingCartList]);



    return <MainView>
        <MainViewSide>
            <TotalAmount>{totalProductsAmount}</TotalAmount>
            <TotalAmountTitle style={{paddingLeft:5,paddingRight:0}}>{" Products (" + totalProductsQuantity + " Pcs)"}</TotalAmountTitle>
        </MainViewSide>
        <MainViewSide>
            <TotalAmountTitle>Total Price : </TotalAmountTitle>
            <TotalAmount style={{paddingLeft:5,paddingRight:0}}>{printPriceOnly(totalPrice) + " TSh"}</TotalAmount>
        </MainViewSide>
    </MainView>

}


export default SalesShoppingBagSummaryComponent;