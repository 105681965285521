import styled from "styled-components";
import { COLORS } from "../../utils/Theme";
import { printPriceOnly } from "../../utils/CommonMethods";
import { Fragment } from "react";




const MainDiv = styled.div`
   padding: 0px 16px 0px 16px;
   display: flex;
   flex-direction: column;
   align-items: stretch;
`;



const StockInfo = styled.div`
   display: flex;
   font-size: 14px;
   color: ${COLORS.colorInstagramBlack};
   font-weight: normal;
`;



const Divider = styled.div`
   background-color: ${COLORS.colorLightGray};
   height: .3px;
   margin: 20px 0px;
`;




const PriceDiv = styled.div`
   display: flex;
   gap: 10px;
`;

const PriceDivChild = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1;
`;


const PriceTitle = styled.h3`
   font-size: 13px;
   color: ${COLORS.colorInstagramBlack};
   font-weight: normal;
`;


const Price = styled.h3`
   margin-top: 3px;
   margin-bottom: 0px;
   font-size: 20px;
   color: ${COLORS.colorInstagramBlack};
   font-weight: bold;
`;










const ProductColorsTitle = styled.p`
   padding: 0px;
   font-size: 13px;
   color: ${COLORS.colorInstagramBlack}; 
`;


const ProductColorsDiv = styled.div`
   margin-top: 10px;
   margin-bottom: 10px;
   display: flex;
   flex-wrap: wrap;
`;

const ProductColorDiv = styled.div`
   box-sizing: content-box;
   padding:3px;
   width: 32px;
   height: 32px;
   margin-right: 8px;
   margin-bottom: 6px;
   border: 2px solid ${props => props.selected ? COLORS.colorInstagramBlack : "transparent"};
   border-radius: 50%;
   transition: all .3s ease-in;

`

const ProductColor = styled.div`
   width: 100%;
   height: 100%;
   border-radius: 50%;
   background-color: ${props => props.color};
`;


function CreateProductColorsDiv(product_colors,currentFocusedMediaItems,setCurrentFocusedMediaItems) {

    return <Fragment>
        <ProductColorsTitle>Colors</ProductColorsTitle>
        <ProductColorsDiv>
            {product_colors.map(color => 
               <ProductColorDiv 
                  key = {color.color_id} 
                  selected = {color.media_items_ids == currentFocusedMediaItems}
                  onClick={() => setCurrentFocusedMediaItems(color.media_items_ids)}>
                   <ProductColor color = {color.hex_value}/>
               </ProductColorDiv>)}
        </ProductColorsDiv>
    </Fragment>
}








const ProductClothingSizesTitle = styled.p`
   font-size: 13px;
   color: ${COLORS.colorInstagramBlack}; 
`;


const ProductClothingSizesDiv = styled.div`
   margin-top: 10px;
   display: flex;
   flex-wrap: wrap;
`;

const ProductClothingSize = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${COLORS.colorInstagramBlack};
  font-weight: bold;
  font-size: 15px;
  width: ${props => props.size_type === "NUMBER_AGE" ? "75px" : "50px"};
  height: 38px;
  padding: 0px;
  margin-right: 8px;
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: ${COLORS.colorLightGray};
`;


function CreateProductClothingSizesDiv(clothing_sizes) {

    return <Fragment>
        <ProductClothingSizesTitle>Sizes</ProductClothingSizesTitle>
        <ProductClothingSizesDiv>
            {clothing_sizes.map(size => <ProductClothingSize key = {size.size_id} size_type={size.size_type}>{size.size}</ProductClothingSize>)}
        </ProductClothingSizesDiv>
    </Fragment>
}









function ProductCenterInfo({productData,currentFocusedMediaItems,setCurrentFocusedMediaItems}) {


    const {product_data} = productData;


    const hasColors = product_data.product_colors && (product_data.product_colors.length > 1);
    const hasSizes = product_data.clothing_sizes;


    return <MainDiv>
        <StockInfo>
            {"Stock :"}
            <span style={{marginLeft:3,fontWeight:'bold'}}>{product_data.product_stock_amount_info.available_for_sale_products + " Pcs"}</span>
            <span style={{fontSize:12,marginLeft:'auto'}}>{product_data.main_media_items.length} Images</span>
        </StockInfo>
        <Divider/>
        <PriceDiv>
            <PriceDivChild>
                <PriceTitle>{"Price (Wholesale - 6pcs)"}</PriceTitle>
                <Price>{"Tsh " + printPriceOnly(productData.price_info.price_wholesale_tzs)}</Price>
            </PriceDivChild>
            <PriceDivChild>
                <PriceTitle>{"Price (Retail)"}</PriceTitle>
                <Price>{"Tsh " + printPriceOnly(productData.price_info.price_retail_tzs)}</Price>
            </PriceDivChild>
        </PriceDiv>
        <Divider/>
        {hasColors && CreateProductColorsDiv(product_data.product_colors,currentFocusedMediaItems,setCurrentFocusedMediaItems)}
        {hasSizes && CreateProductClothingSizesDiv(product_data.clothing_sizes)}
        {(hasColors || hasSizes) && <Divider/>}
    </MainDiv>

}


export default ProductCenterInfo;