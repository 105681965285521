import styled from "styled-components";
import { COLORS } from "../../utils/Theme";
import ProductsListsGrid from "../ProductsListsGrid";




const MainDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: stretch;
   margin: 0px 5px 10px 5px;
`;





const HeaderDiv = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 11px;
`;



const SubCategoryTitle = styled.h2`
   color: ${COLORS.colorInstagramBlack};
   font-size: 16px;
   font-weight: bold;
`;


const SubCategorySellAll = styled.h2`
   color: ${COLORS.colorInstagramBlue};
   font-size: 14px;
   font-weight: bold;
`;









function ProductSubCategoryComponent({siteDetails,subCategory,navigate,setRefreshShoppingCartItemsCountRandomLong,affiliateLinkId}) {

    const {sub_category_title,total_products_amount,visible_products_amount,site_products} = subCategory;

    return <MainDiv>
        <HeaderDiv>
            <SubCategoryTitle>
                {sub_category_title}
                <span style={{fontWeight:"bold",fontSize:14,color:COLORS.colorGray}}>{" (" + total_products_amount + " Products)"}</span>
            </SubCategoryTitle>
            <SubCategorySellAll onClick={() => navigate("/viewSubCategoryProducts?siteId="+ subCategory.site_id + "&subCategoryId=" + subCategory.sub_category_id + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : ""))}>See all</SubCategorySellAll>
        </HeaderDiv>
        <ProductsListsGrid siteDetails={siteDetails} siteProducts={site_products.length === 3 ? [site_products[0],site_products[1]] : site_products} navigate={navigate} subCategoryId={subCategory.sub_category_id} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong} affiliateLinkId={affiliateLinkId}/>
    </MainDiv>

}



export default ProductSubCategoryComponent;