
import styled from "styled-components";
import { formatDateTimeSimpler, printPrice } from "../../utils/CommonMethods";
import { COLORS } from "../../utils/Theme";


const OrderDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 8px 15px 5px 15px;
    background-color: white;
    border-bottom: ${COLORS.colorLightGrayLighter} solid 5px;
`;





const ProductItemDiv = styled.div`
   display: flex;
   align-items: center;
   margin: 8px 0px;
`;


const ProductItemCoverImg = styled.img`
   width: 65px;
   height: 65px;
   flex-shrink: 0;
   object-fit: cover;
   border-radius: 4px;
   margin-left: 0px;
   margin-right: 12px;
`;


const ProductItemInfoDiv = styled.div`
   flex-grow: 1;
   flex-shrink: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
`;




const ProductTitle = styled.h3`
   margin: 0px;
   padding: 0px;
   font-size: 13px;
   font-weight: bold;
   color:  ${COLORS.colorInstagramBlack};
`;


const ProductItemTitle = styled.h3`
   color: ${COLORS.colorGray};
   font-size: 13px;
   font-weight: normal;
`;


const ProductItemPriceToQuantityDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ProductItemPrice = styled.h3`
   font-size: 15px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;


const ProductItemQuantityAmount = styled.p`
    color: ${COLORS.colorGray};
    font-size: 14px;
    font-weight: normal;
`;


function CreateProductItemDiv(productItem,imageClient) {

    return <ProductItemDiv key={"order_product_item_" + productItem.var_group_id}>
       <ProductItemCoverImg src={imageClient.getUrlForImg(80,productItem.item_cover_img)}/>
       <ProductItemInfoDiv>
           <ProductTitle>{productItem.product_label_code}</ProductTitle>
           <ProductItemTitle>{productItem.item_title}</ProductItemTitle>
           <ProductItemPriceToQuantityDiv>
               <ProductItemPrice>{printPrice(productItem.item_price)}</ProductItemPrice>
               <ProductItemQuantityAmount>{"x" + productItem.quantity}</ProductItemQuantityAmount>
           </ProductItemPriceToQuantityDiv>
       </ProductItemInfoDiv>
    </ProductItemDiv>
}


const Divider = styled.div`
   flex-shrink: 0;
   height: 1px;
   opacity: .5;
   background-color: ${COLORS.colorLightGray};
`;


const SectionDiv = styled.div`
   padding: 10px 0px;
   display: flex;
   justify-content: space-between;
   align-items: center;
`;



const SectionTitle = styled.h2`
   font-size: 14px;
   font-weight: normal;
   color: ${COLORS.colorInstagramBlack};
`;



const SectionValue = styled.h2`
   font-size: 14px;
   font-weight: bold;
   color: ${props => props.color || COLORS.colorInstagramBlack};
`;




const OrderStatus = styled.h3`
   font-size: 14px;
`;


const OrderPayPrice = styled.h2`
   font-size: 14px;
   font-weight: bold;
   color: ${COLORS.colorInstagramBlack};
`;



function CreateOrderStatusComponent(order) {
    let textColor;
    let orderStatusTxt = order.order_status;

    if (order.order_status === "UNCONFIRMED") {
        textColor = COLORS.colorGray;
    } else if (order.order_status === "CONFIRMED") {
        textColor = COLORS.colorInstagramBlack;
    } else if (order.order_status === "DELIVERED") {
        textColor = COLORS.colorInstagramBlue;
    } else if (order.order_status === "CANCELLED") {
        textColor = "red";
    } else {
        textColor = "black";
    }

    return <OrderStatus style={{color:textColor}}>{orderStatusTxt}</OrderStatus>
}



function OrderListItemComponent({orderItem,imageClient}) {

    const site_order_params = orderItem.site_order_params;

    return <OrderDiv key={"order_item_" + orderItem.ordered_time}>
        {site_order_params.ordered_product_items.map(productItem => CreateProductItemDiv(productItem,imageClient))}
        <Divider style={{marginTop:10,marginBottom:0}}/>
        <SectionDiv>
            <SectionTitle>Order Status</SectionTitle>
            {CreateOrderStatusComponent(orderItem)}
        </SectionDiv>
        <Divider/>
        <SectionDiv>
            <SectionTitle>Ordered Time</SectionTitle>
            <SectionValue>{formatDateTimeSimpler(orderItem.ordered_time)}</SectionValue>
        </SectionDiv>
        <Divider/>
        <SectionDiv>
            <SectionTitle>Contact Phone</SectionTitle>
            <SectionValue>{site_order_params.contact_phone_number}</SectionValue>
        </SectionDiv>
        <Divider/>
        <SectionDiv>
            <SectionTitle>Delivery Address</SectionTitle>
            <SectionValue>{site_order_params.delivery_address}</SectionValue>
        </SectionDiv>
        <Divider/>
        <SectionDiv>
            <SectionTitle>Total Pay Price</SectionTitle>
            <OrderPayPrice>{printPrice(site_order_params.total_pay_price)}</OrderPayPrice>
        </SectionDiv>
    </OrderDiv>
}




export default OrderListItemComponent;