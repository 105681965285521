import { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams,Link } from "react-router-dom";
import styled from "styled-components";
import useLocalStateManager from "../../utils/LocalStateManager";
import { LoadingComponent } from "../../App";
import useApi from "../../utils/Api";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ProductsListsGrid from "../ProductsListsGrid";
import { COLORS } from "../../utils/Theme";
import { generateSeoMetaTags } from "../../utils/CommonMethods";
import { ApiErrorDiv } from "../../utils/ReusesableComponents";
import useImageLoader from "../../utils/ImageLoader";



const MainDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: stretch;
   width: 100%;
`;




const Title = styled.h1`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding:10px 16px;
   font-size: 15px;
   color: ${COLORS.colorInstagramBlack};
   align-self:flex-start;
   font-weight: bold;
`;


const WebsiteTitleLink = styled(Link)`
   color:${COLORS.colorInstagramBlack};
   cursor: pointer;
   text-decoration: none;
   font-weight: normal;
`;



const RightArrowIcon = styled(ArrowForwardIosSharpIcon)`
   margin: 0px 6px;
   color:${COLORS.colorInstagramBlack};
`;




const ErrorOrLoadingContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center ;
   align-items: center;
   min-height: calc(100vh - 150px);
`;






function ViewSubCategoryProducts({siteDetails,affiliateLinkId,setIsLoading,setRefreshShoppingCartItemsCountRandomLong}) {

    const apiClient = useApi();
    const searchParams = useSearchParams()[0];
    const subCategoryId = searchParams.get("subCategoryId");

    const appState = useLocalStateManager("viewSubCategoryProducts",subCategoryId);

    const [pageData,setPageData] = useState(appState.getState("page_data") || null);

    const [refreshData,setRefreshData] = useState(pageData == null);
    const [errorTxt,setErrorTxt] = useState();

    const navigate = useNavigate();

    const imageClient = useImageLoader();


    useEffect(() => {

        if (refreshData) {
            
            apiClient.getFull(
                "/shop_site/web/get_sub_category_products",
                {sub_category_id:subCategoryId},
                resp => {
                    appState.setState("page_data",resp);
                    setPageData(resp);
                    setRefreshData(false);
                    setIsLoading(false);

                    generateSeoMetaTags(siteDetails.site_header_title + " - " + resp.sub_category.sub_category_title,null,"Products Amount : " + resp.sub_category.visible_products_amount);
                    if (!window.prerenderReady) {
                        window.prerenderReady = true;
                    }

                },error => {
                    setRefreshData(false);
                    setIsLoading(false);
                    setErrorTxt(error);
                });

            setIsLoading(true);
            setErrorTxt(null);
        }

    },[refreshData]);


    useEffect(() => {

        if (pageData && pageData.sub_category) {
            const firstProductCoverImg = pageData.site_products[0]?.product_data?.cover_img?.media_data;
            generateSeoMetaTags(siteDetails.site_header_title + " - " + pageData.sub_category.sub_category_title,firstProductCoverImg ? imageClient.getUrlForImg(100,firstProductCoverImg) : "",pageData.sub_category.total_products_amount + " Products");
        }
        window.scrollTo(0, 0);

    },[pageData]);


    let pageContent;


    if (pageData) {

        const {sub_category,site_products} = pageData;

        pageContent = <Fragment>
            <Title>
                <WebsiteTitleLink to={"/?siteId=" + siteDetails.site_id + (affiliateLinkId ? "&affiliateLinkId=" + affiliateLinkId : "")}>Home</WebsiteTitleLink>
                <RightArrowIcon style={{width:12,height:12}}/>
                {sub_category.sub_category_title}
            </Title>
            <ProductsListsGrid siteDetails={siteDetails} siteProducts={site_products} affiliateLinkId={affiliateLinkId} navigate={navigate} subCategoryId={subCategoryId} setRefreshShoppingCartItemsCountRandomLong={setRefreshShoppingCartItemsCountRandomLong}/>
        </Fragment>


    } else if (refreshData) {
        pageContent = <LoadingComponent/>
    } else if (errorTxt) {
        pageContent = <ErrorOrLoadingContainer>
            <ApiErrorDiv error={errorTxt} refreshPage={() => setRefreshData(true)} refreshButtonText="Retry Again"/>
        </ErrorOrLoadingContainer>
    }

    


    return <MainDiv>
        {pageContent}
    </MainDiv>

}



export default ViewSubCategoryProducts;